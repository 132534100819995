// Constants
import { ADDONS } from '@/constants'
// Services
import { getEveryCategoriesByParentIds, getEveryMenusByPlaceId } from '@/services/category'
import { getEveryDishesByCategoryId } from '@/services/dish'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
// Vuex
import { mapGetters, mapMutations } from 'vuex'
// Utils
import { isNil, get } from 'lodash'

// Version
const version = require('../../package.json').version

export default {
  mixins: [addonsMixin],
  computed: {
    ...mapGetters('place', ['placeData', 'additionalLanguages']),
    ...mapGetters('company', ['companyData']),
    ...mapGetters('authentication', ['userData'])
  },
  methods: {
    ...mapMutations('intercom', ['setIntercom', 'resetIntercom']),
    /**
     * Actualmente solo aplicamos "intercom" en Bakarta
     * pero para posibles limitaciones usaremos este método
     */
    canIUseIntercom() {
      return !isNil(this.$intercom)
    },
    /**
     * Inicializamos "intercom" con los datos que deseamos
     * almacenar del usuario logado
     *
     * @param {object} params - otras opciones a incluir
     */
    async initializeIntercom(params = {}) {
      if (!this.canIUseIntercom()) return
      // Reseteamos la posible existencia de unos datos
      // de intercom de un usuario anterior
      this.intercomShutdown()
      // Datos que deseamos almacenar en el inicio de intercom
      const menus = await getEveryMenusByPlaceId(this.placeData.id)
      const categories = await getEveryCategoriesByParentIds(menus.map((menu) => menu.id))
      const products = await Promise.all(
        categories.map(async (category) => {
          const dishes = await getEveryDishesByCategoryId(category.id)
          return dishes
        })
      )

      const placeConfig = get(this.placeAddonsSetupByUser, ADDONS.place, {})
      // Datos a setear en intercom
      const intercomData = {
        bakarta_menus: menus.length,
        bakarta_categorias: categories.length,
        bakarta_productos: products.flat().length,
        bakarta_idiomas_adicionales: Array.isArray(this.additionalLanguages)
          ? this.additionalLanguages.length
          : 0,
        bakarta_multiestablecimiento: this.hasMultiPlacesAddon,
        bakarta_version: version
      }

      // Seteamos en "store" para después poder usar
      // en cualquier parte de la APP
      this.setIntercom(intercomData)
      // Inicializamos intercom con los datos obtenidos
      this.intercomBoot({
        user_id: this.placeData.id,
        email: this.userData.email,
        name: this.userData.name,
        bakarta_company_id: this.companyData.id,
        bakarta_company: this.companyData.name,
        bakarta_establecimiento: placeConfig.name,
        ...intercomData,
        ...params
      })
    },
    /**
     * Inicializamos "intercom" con los datos que deseamos
     * almacenar del usuario logado
     *
     * @param {options} - opciones con las que inicializar
     */
    intercomBoot(options) {
      if (!this.canIUseIntercom()) return
      this.$intercom.boot(options)
    },
    /**
     * Actualziamos algunos datos de intercom
     *
     * @param {options} - opciones que deseamos actualizar
     */
    intercomUpdate(options) {
      if (!this.canIUseIntercom()) return
      this.$intercom.update(options)
      // Update store
      this.setIntercom(options)
    },
    /**
     * Mostramos el "Messenger"
     */
    intercomShow() {
      if (!this.canIUseIntercom()) return
      this.$intercom.show()
    },
    /**
     * Ocultamos el "Messenger"
     */
    intercomHide() {
      if (!this.canIUseIntercom()) return
      this.$intercom.hide()
    },
    /**
     * "Apagamos" intercom
     */
    intercomShutdown() {
      if (!this.canIUseIntercom()) return
      this.$intercom.shutdown()
      // reset store intercom
      this.resetIntercom()
    },
    /**
     * Lanzamos un evento de intercom
     */
    intercomTrackEvent(name, metadata) {
      if (!this.canIUseIntercom()) return
      this.$intercom.trackEvent(name, metadata)
    }
  }
}
