// Request
import { doRequest } from '@/utils/requestApi'
// Utils
import { isNil, get } from 'lodash'
// Vuex
import store from '@/store'

/**
 * Get all dishes by UID category
 *
 * @param {Array} id - UID category
 */
export const getEveryDishesByCategoryId = async (id) => {
  // UID del item personalizado (placeId en este caso)
  const customItemId = get(store, 'getters.place/placeData.id', null)
  const result = await doRequest({
    method: 'get',
    url: isNil(customItemId)
      ? `v1/dish/dishes/category/${id}`
      : `v1/dish/dishes/category/${id}/${customItemId}`
  })

  return result.data
}
/**
 * Create new dish
 *
 * @param {Object} data - dish data
 * @param {Object} options - opciones de guardado (traducciones...)
 */
export const createDish = async (data, id = null, options = {}) => {
  const result = await doRequest({
    method: 'post',
    url: isNil(id) ? 'v1/dish' : `v1/dish/${id}`,
    data: {
      data,
      options
    }
  })

  return result.data
}
/**
 * Update dish in database
 *
 * @param {Object} data - dish data
 * @param {Object} options - opciones de guardado (traducciones...)
 */
export const updateDishById = async (data, options = {}) => {
  const { id, ...params } = data
  // UID del item personalizado (placeId en este caso)
  const customItemId = get(store, 'getters.place/placeData.id', null)
  const result = await doRequest({
    method: 'patch',
    url: `v1/dish/${id}`,
    data: {
      data: params,
      options: {
        ...options,
        customItemId
      }
    }
  })

  return result.data
}

/**
 * Get dish by UID
 *
 * @param {string} id - UID dish in database
 */
export async function getDishById(id) {
  // UID del item personalizado (placeId en este caso)
  const customItemId = get(store, 'getters.place/placeData.id', null)
  const result = await doRequest({
    method: 'get',
    url: isNil(customItemId) ? `v1/dish/${id}` : `v1/dish/${id}/${customItemId}`
  })

  return result.data
}

/**
 * Remove the dish by id
 *
 * @param {string} id - UID dish in database
 * @param {string} categoryId - UID category in database
 */
export const deleteDishById = async (id, categoryId) => {
  const result = await doRequest({
    method: 'delete',
    url: `v1/dish/${id}/${categoryId}`
  })

  return result.data
}
/**
 * Remove all dishes by ids
 *
 * @param {string} categoryId - UID category
 */
export async function deleteEveryDishesByCategoryId(categoryId) {
  const result = await doRequest({
    method: 'delete',
    url: `v1/dish/dishes/category/${categoryId}`
  })

  return result.data
}
/**
 * Set every dish price to NULL when
 * the category parent (carta) becomes to "menu"
 *
 * @param {string} categoryId - UID category
 */
export const setEveryPricesToNullByCategoryId = async (categoryId) => {
  const result = await doRequest({
    method: 'patch',
    url: `v1/dish/prices-to-null/category/${categoryId}`
  })

  return result.data
}
/**
 * Remove every deleted ration from the dishes
 *
 * @param {Array} rationsIds - UID rations
 * @param {Array} placeId - UID places
 */
export const deleteDishPricesByRationIdsAndPlaceId = async (rationsIds, placeId) => {
  const result = await doRequest({
    method: 'delete',
    url: `v1/dish/prices-to-delete/rations-place`,
    data: {
      rationsIds,
      placeId
    }
  })

  return result.data
}
