// Constants
import { ADDONS, ROLES } from '@/constants'

const PrintMenuPreview = () => import('../views/PrintMenuPreview')

export default [
  {
    path: '/print-menu-preview/:id?',
    name: 'printMenuPreview',
    component: PrintMenuPreview,
    meta: {
      roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value],
      addons: [ADDONS.printMenu]
    }
  }
]
