// Addons
import advancedManagementRoutes from '@/addons/advancedManagement/router/routesName'
import bakartaPayRoutes from '@/addons/bakartaPay/router/routesName'
import contactRoutes from '@/addons/contact/router/routesName'
import crmRoutes from '@/addons/crm/router/routesName'
import featuredItemsRoutes from '@/addons/featuredItems/router/routesName'
import imageGalleryRoutes from '@/addons/imageGallery/router/routesName'
import manageUsersRoutes from '@/addons/manageUsers/router/routesName'
import multiPlacesRoutes from '@/addons/multiPlaces/router/routesName'
import printMenuRoutes from '@/addons/printMenu/router/routesName'
import takeAwayRoutes from '@/addons/takeAway/router/routesName'
// Constants
import { ROLES } from '@/constants'
// Layouts
const DefaultLayout = () => import('@/views/layout/DefaultLayout')
const OnboardingLayout = () => import('@/views/layout/OnboardingLayout')
// Views (Public)
const Login = () => import('@/views/authentication/Login')
const LoginBrand = () => import('@/views/authentication/LoginBrand')
const LoginPartner = () => import('@/views/authentication/LoginPartner')
const Register = () => import('@/views/authentication/Register')
const RecoverPassword = () => import('@/views/authentication/RecoverPassword')
// Views (Checkout)
const CheckoutStepper = () => import('@/views/checkout/CheckoutStepper')
const CheckoutFinish = () => import('@/views/checkout/CheckoutFinish')
// Views (DefaultLayout)
const Dashboard = () => import('@/views/place/Dashboard')
const CategoriesList = () => import('@/views/categories/CategoriesList')
const DishesList = () => import('@/views/dishes/DishesList')
const UserByTabs = () => import('@/views/user/UserByTabs')
const UserFirstAccess = () => import('@/views/user/UserFirstAccess')
const ReferrerLink = () => import('@/views/company/ReferrerLink')
const ReferrerCatcher = () => import('@/views/place/ReferrerCatcher')
const ShareMenuLink = () => import('@/views/place/ShareMenuLink')
const ConfigMain = () => import('@/views/config/ConfigMain')
const ConfigBase = () => import('@/views/config/ConfigBase')
const ConfigWeb = () => import('@/views/config/ConfigWeb')
const ConfigMiscellaneous = () => import('@/views/config/ConfigMiscellaneous')
const RationsList = () => import('@/views/rations/RationsList')
const PlansList = () => import('@/views/plans/PlansList')
const PlansDetail = () => import('@/views/plans/PlansDetail')
const PlansGuide = () => import('@/views/plans/PlansGuide')
const AddonsNeedUpgrade = () => import('@/views/addons/AddonsNeedUpgrade')
const BillingByTabs = () => import('@/views/company/BillingByTabs')
const WalletByTabs = () => import('@/views/company/WalletByTabs')
const AcceptRelationships = () => import('@/views/company/AcceptRelationships')
const AdsByTabs = () => import('@/views/place/AdsByTabs')
// Views (OnboardingLayout)
const OnboardingMenu = () => import('@/views/onboarding/OnboardingMenu')
const OnboardingCategory = () => import('@/views/onboarding/OnboardingCategory')
const OnboardingProduct = () => import('@/views/onboarding/OnboardingProduct')
const OnboardingFinish = () => import('@/views/onboarding/OnboardingFinish')

export default [
  // DefaultLayout
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/menus',
        name: 'menus',
        component: CategoriesList,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/categories/:id',
        name: 'categories',
        component: CategoriesList,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/dishes/:id',
        name: 'dishes',
        component: DishesList,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/referrer-link',
        name: 'referrerLink',
        component: ReferrerLink,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/share-menu-link',
        name: 'shareMenuLink',
        component: ShareMenuLink,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/rations',
        name: 'rations',
        component: RationsList,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      // Config
      {
        path: '/config',
        name: 'config',
        component: ConfigMain,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/config-base',
        name: 'configBase',
        component: ConfigBase,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/config-web',
        name: 'configWeb',
        component: ConfigWeb,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      {
        path: '/config-miscellaneous',
        name: 'configMiscellaneous',
        component: ConfigMiscellaneous,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      // User
      {
        path: '/profile',
        name: 'profile',
        component: UserByTabs,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      // Billing
      {
        path: '/billing',
        name: 'Billing',
        component: BillingByTabs,
        meta: {
          roles: [ROLES.horeca_admin.value]
        }
      },
      // Wallet
      {
        path: '/wallet',
        name: 'Wallet',
        component: WalletByTabs,
        meta: {
          roles: [ROLES.horeca_admin.value]
        }
      },
      // Ad
      {
        path: '/ads',
        name: 'Ads',
        component: AdsByTabs,
        meta: {
          roles: [ROLES.horeca_admin.value]
        }
      },
      // Plans
      {
        path: '/plans',
        name: 'plans',
        component: PlansList,
        meta: {
          roles: [ROLES.horeca_admin.value]
        }
      },
      {
        path: '/plans/:id',
        name: 'plansDetail',
        component: PlansDetail,
        meta: {
          roles: [ROLES.horeca_admin.value]
        }
      },
      {
        path: '/plans-guide',
        name: 'plansGuide',
        component: PlansGuide,
        meta: {
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      },
      // Relationships
      {
        path: '/accept-relationships/:id',
        name: 'acceptRelationships',
        component: AcceptRelationships,
        meta: {
          roles: [ROLES.horeca_admin.value]
        }
      },
      // Addons
      {
        path: '/addons/:id/need-upgrade',
        name: 'addonsNeedUpgrade',
        component: AddonsNeedUpgrade,
        meta: {
          roles: [ROLES.horeca_admin.value]
        }
      },
      // Addons
      ...advancedManagementRoutes,
      ...bakartaPayRoutes,
      ...contactRoutes,
      ...crmRoutes,
      ...featuredItemsRoutes,
      ...imageGalleryRoutes,
      ...manageUsersRoutes,
      ...multiPlacesRoutes,
      ...printMenuRoutes,
      ...takeAwayRoutes
    ]
  },
  // OnboardingLayout
  {
    path: '/onboarding',
    component: OnboardingLayout,
    children: [
      {
        path: 'menu',
        name: 'onboardingMenu',
        component: OnboardingMenu,
        meta: {
          roles: [ROLES.horeca_admin.value]
        }
      },
      {
        path: 'category/:id',
        name: 'onboardingCategory',
        component: OnboardingCategory,
        meta: {
          roles: [ROLES.horeca_admin.value]
        }
      },
      {
        path: 'product/:id',
        name: 'onboardingProduct',
        component: OnboardingProduct,
        meta: {
          roles: [ROLES.horeca_admin.value]
        }
      },
      {
        path: 'finish',
        name: 'onboardingFinish',
        component: OnboardingFinish,
        meta: {
          roles: [ROLES.horeca_admin.value]
        }
      }
    ]
  },
  // Checkout
  {
    path: '/checkout/:id',
    name: 'checkout',
    component: CheckoutStepper
  },
  {
    path: '/checkout-finish',
    name: 'checkoutFinish',
    component: CheckoutFinish
  },
  // User
  {
    path: '/first-access',
    name: 'firstAccess',
    component: UserFirstAccess,
    meta: {
      roles: [ROLES.horeca_admin.value]
    }
  },
  // Auth
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/login-partner',
    name: 'loginPartner',
    component: LoginPartner,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/login-brand',
    name: 'loginBrand',
    component: LoginBrand,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/recover-password',
    name: 'recoverPassword',
    component: RecoverPassword,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      isPublic: true
    }
  },
  // Others
  {
    path: '/ref/:id',
    name: 'ref',
    component: ReferrerCatcher,
    meta: {
      isPublic: true
    }
  },
  { path: '*', redirect: '/' }
]
