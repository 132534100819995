export default {
  user: undefined,
  // Onboarding solo para usuarios que se registran (admin)
  onboarding: [
    {
      id: 'menu',
      params: null
    },
    {
      id: 'category',
      params: null
    },
    {
      id: 'product',
      params: null
    }
  ]
}
