<template>
  <VuetifyLoadingLayer v-bind="appLoadingLayer" />
</template>

<script>
// Vuex
import { mapState } from 'vuex'
// Components
import VuetifyLoadingLayer from '@/components/ui/VuetifyLoadingLayer'

export default {
  name: 'AppLoadingLayer',
  components: { VuetifyLoadingLayer },
  computed: {
    ...mapState('app', ['appLoadingLayer'])
  }
}
</script>
