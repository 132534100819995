import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import es from 'vuetify/es5/locale/es'
// constant
import { THEME_PRIMARY_COLOR, THEME_SECONDARY_COLOR, THEME_BACKGROUND } from '@/constants'
// Custom icons
import ContactIcon from '@/components/ui/ContactIcon'
import GalleryIcon from '@/components/ui/GalleryIcon'
import TripAdvisorIcon from '@/components/ui/TripAdvisorIcon'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
    // Custom icons
    values: {
      'custom-contact': {
        component: ContactIcon
      },
      'custom-gallery': {
        component: GalleryIcon
      },
      'custom-tripadvisor': {
        component: TripAdvisorIcon
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  },
  theme: {
    themes: {
      light: {
        secondary: THEME_SECONDARY_COLOR,
        primary: THEME_PRIMARY_COLOR,
        background_color: THEME_BACKGROUND
      },
      dark: {
        secondary: THEME_SECONDARY_COLOR,
        primary: THEME_PRIMARY_COLOR,
        background_color: THEME_BACKGROUND
      }
    }
  }
})
