import firebase from 'firebase/app'
import 'firebase/messaging'

// Utils
import { get, isNil } from 'lodash'

/**
 * Manejamos el error de obtención del token
 *
 * @param {object} err - error en petición
 * @return {string}
 */
export function handleError(err) {
  const code = get(err, 'code', null)
  let message = get(err, 'message', null)

  if (code === 'messaging/permission-default') {
    message =
      'No tienes activadas las notificaciones en este dispositivo. Cuando el "diálogo de notificaciones" aparezca, debes "Aceparlo".'
  } else if (code === 'messaging/notifications-blocked') {
    message =
      'Has bloqueado las notificaciones de este sitio en el navegador. Deberás volver a permitirlas. Para habilitar las notificaciones en Android o Chrome deberás seguir las siguientes intrucciones: <a href="https://support.google.com/chrome/answer/114662?visit_id=1-636150657126357237-2267048771&rd=1&co=GENIE.Platform%3DAndroid&oco=1">Android Chrome Instructions</a><a href="https://support.google.com/chrome/answer/6148059">Desktop Chrome Instructions</a>'
  }

  return message || 'Ocurrió un error con las notificaciones Push'
}

/**
 * Obtenemos Token del dispositivo para usar en Firebase
 *
 * @return {String}
 */
export async function getToken() {
  try {
    const result = await firebase.messaging().getToken()
    return result
  } catch (err) {
    throw new Error(handleError(err))
  }
}
/**
 * Poseemos soporte de notificaciones?
 *
 * @return {boolean}
 */
export function hasSupport() {
  return firebase.messaging.isSupported() && window.Notification instanceof Function
}
/**
 * Estado actual de las notificaciones
 *
 * @return {string}
 */
export function status() {
  return Notification.permission
}
/**
 * Petición de notificaciones push en el dispositivo
 *
 * @return {string}
 */
export async function requestPermission() {
  if (hasSupport()) {
    if (status() !== 'granted') {
      const result = await Notification.requestPermission()
      return result === 'granted'
    }
    return true
  }
  return false
}

/**
 * Función que captura los mensajes y los muestra
 *
 * @param {object} payload
 * @return {object}
 */
export function handleMessage(payload) {
  if (!hasSupport() || isNil(payload) || isNil(payload.notification)) return

  const { notification } = payload
  const { title, ...params } = notification

  // Mostramos notificación
  new Notification(title, params)
}

/**
 * Inicializamos la escucha de los mensajes
 *
 * @param {function} fn - función que capturará el payload de la notificación
 */
export function init(fn = null) {
  if (hasSupport()) {
    firebase.messaging().onMessage(isNil(fn) || typeof fn !== 'function' ? handleMessage : fn)
  }
}
