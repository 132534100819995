// Request
import { doRequest } from '@/utils/requestApi'
// Utils
import { isNil } from 'lodash'

/**
 * Creamos una nueva suscripción
 *
 * @param {object} data - datos de la suscripción
 * @param {string} id - UID de la suscripción
 * @return {object}
 */
export async function createSubscription(data, id = null) {
  const result = await doRequest({
    method: 'post',
    url: isNil(id) ? 'v1/subscription' : `v1/subscription/${id}`,
    data
  })

  return result.data
}

/**
 * Actualizamos una subscripción
 *
 * @param {Object} data - datos de la suscripción
 */
export async function updateSubscriptionById(data) {
  const { subscription = {}, stripe = {} } = data
  const { id, ...params } = subscription
  const result = await doRequest({
    method: 'patch',
    url: `v1/subscription/${id}`,
    data: {
      stripe,
      subscription: params
    }
  })

  return result.data
}

/**
 * Cancelamos una suscripción
 *
 * @param {object} data - datos a enviar
 * @return {object}
 */
export async function cancelSubscriptionById(data) {
  const {
    subscriptionId,
    stripeSubscriptionId = null,
    cancelInmediatly = false,
    canceledFor = 'Cancelada por el usuario'
  } = data
  const result = await doRequest({
    method: 'delete',
    url: `v1/subscription/${subscriptionId}`,
    data: {
      canceledFor,
      cancelInmediatly,
      stripeSubscriptionId
    }
  })

  return result.data
}

/**
 * Reactivamos una suscripción
 *
 * @param {object} data - datos a enviar
 * @return {object}
 */
export async function reactivateSubscriptionById(data) {
  const { stripeSubscriptionId = null, subscriptionId } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/subscription/reactivate/${subscriptionId}`,
    data: {
      stripeSubscriptionId
    }
  })

  return result.data
}
