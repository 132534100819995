// Contants
import { ADDON_TYPES, REFERRALS_STATE } from '@/constants'
// Utils
import { get, isNil } from 'lodash'
import { getNavigatorLanguage } from '@/utils'
// Formato Telefono
import { PhoneNumberUtil, PhoneNumberFormat as PNF } from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

// Date
const moment = require('moment')
// Html
const sanitizeHtml = require('sanitize-html')
// Tipos de movimientos (cartera)
const MOVEMENTS_TYPES = {
  add: {
    label: 'Entrada',
    value: 'add'
  },
  remove: {
    label: 'Salida',
    value: 'remove'
  }
}
// Estado de las facturas
const INVOICES_STATE = {
  outstanding: {
    label: 'Esperando pago',
    value: 'outstanding'
  },
  late: {
    label: 'Factura vence, sin pago',
    value: 'late'
  },
  paid: {
    label: 'Pagado',
    value: 'paid'
  },
  refunded: {
    label: 'Reembolsado',
    value: 'refunded'
  },
  archived: {
    label: 'Anulada',
    value: 'archived'
  }
}

/**
 * Obtenemos el tipo de "addOn" (etiqueta)
 *
 * @param {String} type - tipo de addOn
 * @return {String}
 */
export function addOnTypeLabel(type) {
  return get(ADDON_TYPES, `${type}.label`, 'no definido')
}

/**
 * Obtenemos el color del estado de la factura
 *
 * @param {String} status
 * @return {String}
 */
export function invoiceStatusColor(status) {
  const statusColor = {
    [INVOICES_STATE.archived.value]: 'blue-grey lighten-4',
    [INVOICES_STATE.late.value]: 'red',
    [INVOICES_STATE.outstanding.value]: 'orange',
    [INVOICES_STATE.paid.value]: 'light-green',
    [INVOICES_STATE.refunded.value]: 'cyan'
  }

  return get(statusColor, status, 'blue-grey lighten-4')
}

/**
 * Obtenemos la etiqueta del estado de la factura
 *
 * @param {String} status
 * @return {String}
 */
export function invoiceStatusLabel(status) {
  return get(INVOICES_STATE, `${status}.label`, 'No definido')
}

/**
 * Obtenemos el color del estado de la referencia
 *
 * @param {String} status
 * @return {String}
 */
export function referralStatusColor(status) {
  const statusColor = {
    [REFERRALS_STATE.pending.value]: 'blue-grey lighten-4',
    [REFERRALS_STATE.activated.value]: 'light-green'
  }

  return get(statusColor, status, 'blue-grey lighten-4')
}

/**
 * Obtenemos la etiqueta del tipo de movimiento
 *
 * @param {String} type
 * @return {String}
 */
export function movementTypeLabel(type) {
  return get(MOVEMENTS_TYPES, `${type}.label`, 'No definido')
}

/**
 * Obtenemos el color del tipo de movimiento
 *
 * @param {String} type
 * @return {String}
 */
export function movementTypeColor(type) {
  const typeColor = {
    [MOVEMENTS_TYPES.add.value]: 'light-green',
    [MOVEMENTS_TYPES.remove.value]: 'red accent-1'
  }

  return get(typeColor, type, 'blue-grey lighten-4')
}

/**
 * Obtenemos la etiqueta del estado de la referencia
 *
 * @param {String} status
 * @return {String}
 */
export function referralStatusLabel(status) {
  return get(REFERRALS_STATE, `${status}.label`, 'No definido')
}

/**
 * Formateamos número tarjeta
 *
 * @param {String} number (últimos 4)
 * @return {String} - fecha formateada
 */
export function formatCardNumber(number = '****') {
  return `**** **** **** ${number}`
}

/**
 * Formateamos fecha
 *
 * @param {any} date
 * @param {string} format
 * @param {boolean} utc - Universal time
 * @return {String} - fecha formateada
 */
export function formatDate(date, format = 'D MMMM YYYY, h:mm a', utc = true) {
  if (isNil(date)) {
    return date
  }

  const now = new Date()

  let currentDate = date

  if (typeof date.toDate === 'function') {
    currentDate = date.toDate()
  } else if (typeof date === 'number') {
    const mSeconds = utc ? date + now.getTimezoneOffset() * 60000 : date
    currentDate = new Date(mSeconds)
  } else if (date._seconds) {
    const mSeconds = utc
      ? date._seconds * 1000 + now.getTimezoneOffset() * 60000
      : date._seconds * 1000
    currentDate = new Date(mSeconds)
  } else if (date.seconds) {
    const mSeconds = utc
      ? date.seconds * 1000 + now.getTimezoneOffset() * 60000
      : date.seconds * 1000

    currentDate = new Date(mSeconds)
  }

  return moment(currentDate).locale(getNavigatorLanguage()).format(format)
}

/**
 * Formateamos moneda
 *
 * @param {Number} price
 * @param {String} currency
 * @param {Boolean} showZero
 * @return {String} - precio formateado
 */
export function formatPrice(price, currency = 'EUR', showZero = false) {
  const currentCurrency = typeof currency === 'string' ? currency.toUpperCase() : 'EUR'

  return !isNaN(price) || (price === 0 && showZero)
    ? new Intl.NumberFormat(getNavigatorLanguage(), {
        style: 'currency',
        currency: currentCurrency
      }).format(price)
    : '-'
}

/**
 * Formateamos número al estilo del país del navegador
 *
 * @param {Number} value
 * @return {String} - número formateado
 */
export function formatNumber(value) {
  return !isNaN(value) ? new Intl.NumberFormat(getNavigatorLanguage()).format(value) : '-'
}

/**
 * Formateamos número de teléfono
 *
 * @param {string} phone
 * @return {string}
 */
export function formatPhoneNumber(value, countryCode = 'ES', format = 'NATIONAL') {
  try {
    const parsedPhoneNumber = phoneUtil.parse(value, countryCode)
    return phoneUtil.format(parsedPhoneNumber, PNF[format])
  } catch (error) {
    return value
  }
}

/**
 * Parseamos el objeto "place" devuelto por GoogleMaps Autocomplete
 * para mostrarlo de un modo más humano
 *
 * @param {Object} place
 * @return {String}
 */
export function humanizePlaceObject(place = {}) {
  if (typeof place === 'string') {
    return place
  }

  // Partes de la dirección
  const elements = []

  if (get(place, 'route', null)) {
    const address = `${get(place, 'route')} ${get(place, 'street_number', '')}`
    elements.push(address)
  }

  if (get(place, 'locality', null)) {
    const locality = `${get(place, 'locality')} ${get(place, 'postal_code', '')}`
    elements.push(locality)
  }

  if (get(place, 'administrative_area_level_2', null)) {
    const state = get(place, 'administrative_area_level_2')
    const country = get(place, 'country') ? '(' + get(place, 'country') + ')' : ''
    elements.push(`${state} ${country}`)
  }

  return isNil(elements[0]) ? '' : elements.join(',')
}

/**
 * This function is same as PHP's nl2br() with default parameters.
 *
 * @param {string} str Input text
 * @param {Boolean} replaceMode Use replace instead of insert
 * @param {Boolean} isXhtml Use XHTML
 * @return {string} Filtered text
 */
export function nl2br(str, replaceMode = true, isXhtml = true) {
  if (isNil(str)) return ''

  const strWithHTML = sanitizeHtml(str, {
    allowedTags: ['br'],
    allowedAttributes: {}
  })
  const breakTag = isXhtml ? '<br />' : '<br>'
  const replaceStr = replaceMode ? '$1' + breakTag : '$1' + breakTag + '$2'
  return (strWithHTML + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr)
}

/**
 * Limpia contenido de etiquetas HTML no permitidas
 *
 * @param {string} str Input text
 * @param {array} allowedTags allowed tags
 * @param {array} allowedAttributes allowed attributes
 * @param {object} options other options
 * @return {string} Filtered text
 */
export function sanitizeHtmlContent(
  str,
  allowedTags = ['b', 'i', 'em', 'strong', 'u', 's', 'br', 'p'],
  allowedAttributes = {},
  options = {}
) {
  if (isNil(str)) return ''

  return sanitizeHtml(str, {
    allowedTags,
    allowedAttributes,
    ...options
  }).replace(/&amp;/g, '&') // Solución adHoc para el problema con los "&"
}

/**
 * Eliminamos extensión el fichero
 *
 * @param {string} file
 * @return {string}
 */
export function removeExtentionFile(file) {
  return isNil(file) ? file : file.split('.').slice(0, -1).join('.')
}
