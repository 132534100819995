// Constants
import { ADDONS, ROLES } from '@/constants'

const ContactConfigPlace = () => import('../views/config/ContactConfigPlace')
const ContactConfigSchedule = () => import('../views/config/ContactConfigSchedule')

export default [
  {
    path: '/contact-config-place',
    name: 'contactConfigPlace',
    component: ContactConfigPlace,
    meta: {
      roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value],
      addons: [ADDONS.contact]
    }
  },
  {
    path: '/contact-config-schedule',
    name: 'contactConfigSchedule',
    component: ContactConfigSchedule,
    meta: {
      roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value],
      addons: [ADDONS.contact]
    }
  }
]
