// Request
import { doRequest } from '@/utils/requestApi'
// Utils
import { get, isNil } from 'lodash'

/**
 * Create new place
 *
 * @param {Object} data - place data
 */
export async function createPlace(data) {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'post',
    url: isNil(id) ? 'v1/place/create' : `v1/place/create/${id}`,
    data: params
  })

  return result.data
}

/**
 * Update place in database
 *
 * @param {Object} data - place data
 * @return {object}
 */
export async function updatePlaceById(data) {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/place/${id}`,
    data: {
      ...params
    }
  })

  return result.data
}

/**
 * Get place by UID
 *
 * @param {string} id - UID place in database
 * @param {object} options - options to get data
 * @return {object}
 */
export async function getPlaceById(
  id,
  options = { includeAddOnsConfigs: true, includeSubscriptions: true }
) {
  const result = await doRequest({
    method: 'post',
    url: `v1/place/${id}`,
    data: {
      options
    }
  })

  return result.data
}

/**
 * Get all places by id
 *
 * @param {Array} ids - UID place in database
 */
export async function getEveryPlacesByIds(
  ids,
  options = { includeAddOnsConfigs: true, includeSubscriptions: true }
) {
  const result = await doRequest({
    method: 'post',
    url: `v1/place/places`,
    data: {
      ids,
      options
    }
  })

  return result.data
}

/**
 * Obtenemos los "deliverySlots" de un establecimiento y fecha
 *
 * @param {String} placeId - Identificador del establecimiento
 * @param {Number} date - timestamp de la fecha
 * @param {Array} - Array con los diferentes slots
 */
export async function getDeliverySlotsByPlaceIdAndDate(placeId, date) {
  // enviamos el uso horario en el que nos encontramos
  const utc = -1 * (new Date().getTimezoneOffset() / 60)
  const result = await doRequest({
    method: 'post',
    url: `v1/place/delivery-slots/${placeId}`,
    data: {
      date,
      utc
    }
  })

  return result.data
}

/**
 * Obtenemos los beneficios mensuales por establecimiento
 *
 * @param {Array} ids - UID place in database
 */
export async function getAdvertisingLinesByPlaceId(placeId) {
  const result = await doRequest({
    method: 'get',
    url: `v1/place/advertising-lines/${placeId}`
  })

  return result.data
}

/**
 * Actualizar configuraciones del establecimiento
 *
 * @param {Object} data - datos petición
 * @return {object}
 */
export async function updateAddOnConfigs(data) {
  const { id, addOn } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/place/add-on-configs/${id}`,
    data: {
      addOn
    }
  })

  return result.data
}

/**
 * Obtenemos las facturas (Stripe) de los establecimientos
 *
 * @param {Object} email - email de factauración
 * @return {object}
 */
export async function getInvoicesByEmail(email) {
  const result = await doRequest({
    method: 'post',
    url: 'v1/place/invoices',
    data: {
      email
    }
  })

  return result.data
}

/**
 * Check if the url exists in the database
 *
 * @param {string} url - public url
 * @param {string} id - place id
 */
export const isUrlAvailable = async (url, id) => {
  const result = await doRequest({
    method: 'post',
    url: `v1/place/url-available/${id}`,
    data: {
      url
    }
  })

  return result.data
}

/**
 * Genera la url del place a partir del nombre de compañia
 *
 * @param {string} name
 */
export const getUrlPlace = async (name) => {
  const result = await doRequest({
    method: 'post',
    url: `v1/place/url-place`,
    data: {
      name
    }
  })

  return result.data
}

/**
 * Obtenemos los datos de las estadísticas del establecimiento
 *
 * @param {string} id - UID establecimiento
 * @param {string} fromDate - Fecha desde
 * @param {string} fromTo - Fecha hasta
 * @param {boolean} compareIntervals - Realizamos búsqueda comparando intervalo anterior
 */
export const getAnalyticsDataByPlaceId = async (id, fromDate, toDate, compareIntervals = true) => {
  const result = await doRequest({
    method: 'post',
    url: `v1/place/analytics-data/${id}`,
    data: {
      fromDate,
      toDate,
      compareIntervals
    }
  })

  // Parsemos los datos antes de devolverlos
  const data = get(result, 'data', null)
  const visits = get(result, 'data.visits', null)

  if (isNil(data) || isNil(visits)) {
    return data
  }
  // Eliminamos array de visitas (dentro del objeto)
  delete data.visits

  // Devolvemos el objeto ya completado con sus datos
  return {
    ...visits.reduce((sumVisits, visit) => {
      let key = null

      if (visit.link === 'qr') {
        key = 'totalVisitsQR'
      } else if (visit.link === 'url') {
        key = 'totalVisitsURL'
      } else if (visit.link.includes(id)) {
        key = 'totalVisitsQR'
      } else {
        key = 'totalVisitsURL'
      }

      sumVisits[key] = visit.value
      sumVisits[`${key}IncrementPeriods`] = visit.increment
      return sumVisits
    }, {}),
    ...data
  }
}

/**
 * Obtenemos los datos de las ventas del establecimiento (Chart)
 *
 * @param {string} id - UID establecimiento
 * @param {number} daysAgo - Números de días a aplicar consulta
 */
export const getOrdersDataForDashboardByPlaceId = async (id, daysAgo) => {
  const result = await doRequest({
    method: 'post',
    url: `v1/place/orders-data-dashboard/${id}`,
    data: {
      daysAgo
    }
  })

  return result.data
}

/**
 * Creamos un menu compelto (categorías y platos) completo a través
 * de un UID de establecimiento y los datos a crear
 *
 * @param {String} id - UID del establecimiento
 * @param {Object} data - datos del menú a crear
 */
export async function createMenuByData(id, data) {
  const result = await doRequest({
    method: 'post',
    url: 'v1/place/menu',
    data: {
      id,
      data
    }
  })

  return result.data
}
