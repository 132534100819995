<template>
  <svg
    id="contact-icon"
    height="512"
    viewBox="0 0 74 74"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m45.279 61.17a1 1 0 0 1 -.82-1.572c.547-.785 1.113-1.6 1.669-2.432a1 1 0 1 1 1.664 1.109c-.564.846-1.138 1.671-1.691 2.467a1 1 0 0 1 -.822.428z"
    />
    <path
      d="m37 72a1 1 0 0 1 -.758-.348 159.756 159.756 0 0 1 -11.618-15.8c-7.759-12.068-11.694-22.089-11.694-29.782a24.07 24.07 0 0 1 48.14 0c0 4.8-1.57 10.6-4.664 17.223a1 1 0 0 1 -1.812-.846c2.97-6.36 4.476-11.87 4.476-16.377a22.07 22.07 0 0 0 -44.14 0c0 15.665 18.109 38.552 22.07 43.369.7-.844 1.83-2.244 3.217-4.048a1 1 0 1 1 1.583 1.218c-2.379 3.1-4.028 5.023-4.044 5.042a1 1 0 0 1 -.756.349z"
    />
    <path
      d="m50.775 52.682a.942.942 0 0 1 -.48-.128.971.971 0 0 1 -.38-1.336c.523-.9 1.009-1.736 1.495-2.592a1 1 0 1 1 1.739.988c-.489.862-.979 1.7-1.469 2.547a1.057 1.057 0 0 1 -.905.521z"
    />
    <path
      d="m37 43.6a17.3 17.3 0 1 1 17.3-17.3 17.32 17.32 0 0 1 -17.3 17.3zm0-32.6a15.3 15.3 0 1 0 15.3 15.3 15.317 15.317 0 0 0 -15.3-15.3z"
    />
    <path
      d="m42.312 36.047a7.462 7.462 0 0 1 -3.439-1.167 27.63 27.63 0 0 1 -5.884-4.68 27.61 27.61 0 0 1 -4.5-6.023c-1.255-2.393-1.4-4.081-.434-5.019l2.1-2.047a2.152 2.152 0 0 1 3.041.045l2.265 2.334a2.152 2.152 0 0 1 -.046 3.039l-.609.594a8.147 8.147 0 0 0 5.307 5.472l.605-.595a2.152 2.152 0 0 1 3.041.043l2.265 2.335a2.149 2.149 0 0 1 -.046 3.04l-2.1 2.047a2.152 2.152 0 0 1 -1.566.582zm-10.661-17.547a.15.15 0 0 0 -.1.042l-2.1 2.047c-.37.661 1.267 4.391 4.976 8.216s7.385 5.572 8.076 5.214l2.084-2.035a.15.15 0 0 0 0-.213l-2.265-2.334a.149.149 0 0 0 -.211 0l-.782.764c-1.29 1.254-4.534-.794-5.985-2.29s-3.4-4.8-2.108-6.052l.783-.764a.149.149 0 0 0 .005-.213l-2.264-2.335a.152.152 0 0 0 -.109-.047z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ContactIcon'
}
</script>
