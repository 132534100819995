// Constants
import { ADDONS, ROLES } from '@/constants'

const ManageUsersList = () => import('../views/ManageUsersList')

export default [
  {
    path: '/manage-users',
    name: 'manageUsers',
    component: ManageUsersList,
    meta: {
      roles: [ROLES.horeca_admin.value],
      addons: [ADDONS.manageUsers]
    }
  }
]
