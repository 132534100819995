import { get, keys, isNil } from 'lodash'
import firebase from 'firebase/app'

const moment = require('moment')

/**
 * Obtenemos variables de entorno
 * (función creada para intercalar entre variables del entorno
 * "base" y el entorno de "cypress")
 *
 * @param {string} name - nombre de la variable
 * @returns
 */
export function getEnvironmentVariable(name) {
  return !isNil(window.Cypress) ? window.Cypress.env(name) : process.env[name]
}
/**
 * String to Date
 *
 * @param {string} date
 * @param {object} options { format, output }
 * @return {Date | Number}
 */
export function stringToDate(date = null, options = { format: 'DD/MM/YYYY', output: 'timestamp' }) {
  const momentDate = moment(date, options.format, true)

  if (!momentDate.isValid()) {
    return null
  }

  return options.output === 'timestamp' ? momentDate.valueOf() : momentDate.toDate()
}
/**
 * Check if a date string is right format
 *
 * @param {string} date
 * @param {string} format
 * @return {Boolean}
 */
export function isDateFormat(date = null, format = 'DD/MM/YYYY') {
  const momentDate = moment(date, format, true)
  return momentDate.isValid()
}
/**
 * Only allow numbers, letters (no latins) and '-'
 *
 * @param {string} string - string to check
 * @return {Boolean}
 */
export function isOnlyNumbersLettersAndDash(string = null) {
  return /^[a-zA-Z0-9-]*$/.test(string)
}
/**
 * Decimal numbers with "," and "."
 *
 * @param {string} string - string to check
 * @return {Boolean}
 */
export function isDecimalNumber(string = null) {
  return /^[0-9]+([,|.][0-9]+)?$/.test(string)
}
/**
 * Es un video de "Youtube"?
 *
 * @param {string} url
 * @return {boolean}
 */
export function isYoutubeUrl(url = null) {
  return /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})?$/.test(
    url
  )
}
/**
 * Convert to the correct number format
 *
 * @param {string} number - number (string) to convert
 * @return {Number}
 */
export function stringToNumber(number) {
  if (typeof number !== 'string' || (typeof number === 'string' && number === '')) {
    return number
  }

  const parseNumber = Number.parseFloat(number.replace(/,/gi, '.'))

  return Number.isNaN(parseNumber) ? number : parseNumber
}
/**
 * Return public image path
 *
 * @param {string} image - image name
 * @param {Boolean} prefixBrand - put the brand prefix
 * @return {string}
 */
export function getPathImage(image = null, prefixBrand = false) {
  const prefix = prefixBrand ? `/${getEnvironmentVariable('VUE_APP_BRAND')}` : ''
  return image ? `/img${prefix}/${image}` : ''
}
/**
 * Transform a file into base64
 *
 * @param {Object} file - file object
 * @return {Promise}
 */
export async function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
/**
 * Base64 to Blob (file)
 *
 * @param {string} dataURI - file object
 */
export function dataURItoBlob(dataURI) {
  const bytes =
    dataURI.split(',')[0].indexOf('base64') >= 0
      ? atob(dataURI.split(',')[1])
      : unescape(dataURI.split(',')[1])
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const max = bytes.length
  const ia = new Uint8Array(max)

  for (let i = 0; i < max; i += 1) {
    ia[i] = bytes.charCodeAt(i)
  }
  return new Blob([ia], { type: mime })
}
/**
 * Obtiene un fichero de una URL y lo pasa a BLOB
 *
 * @param {string} url - url to get file
 */
export async function getFileFromUrl(url) {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.onload = () => {
      resolve(xhr.response)
    }
    xhr.open('GET', url)
    xhr.send()
  })
}
/**
 * Get Language (locale) from navigator
 */
export function getNavigatorLanguage() {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0]
  }
  return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'es'
}
/**
 * Check if is "firebase.firestore.TimeStamp"
 *
 * @param {Object} date - firebase object
 */
export function isFBTimeStamp(date) {
  const fbTimeStampIntance = get(firebase, 'firestore.Timestamp', false)
  return fbTimeStampIntance == !false ? date instanceof fbTimeStampIntance : false
}
/**
 * Convert all firebase object Timestamp properties to date
 *
 * @param {Object} data - firebase object
 */
export function convertFirebaseTimestampPropertiesToDate(data) {
  const newObj = {}

  keys(data)
    .filter((prop) => data[prop] instanceof Object)
    .forEach((prop) => {
      if (isFBTimeStamp(data[prop])) {
        newObj[prop] = data[prop].toDate()
      }
    })

  return {
    ...data,
    ...newObj
  }
}
/**
 * Devuelve el valor de la variable "brand"
 *
 * @param {string} brand
 * @return {string}
 */
export function getCurrentBrand(brand) {
  return isNil(brand) ? 'bakarta' : brand
}
/**
 * Combinación de setTimeout con "Promise" para conseguir
 * que las ejecuciones se paren "N" segundos
 *
 * @param {Number} ms - milisegundos de parada
 * @return {Promise}
 */
export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Obtenemos números aleatorios
 *
 * @param {Number} size - Tamaño máximo del número aleatorio
 * @return {Number}
 */
export function randomNumber(size) {
  return Math.floor(Math.random() * size)
}
