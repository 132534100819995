// Request
import { doRequest } from '@/utils/requestApi'
/**
 * Mendiante el UID de un usuario, creamos un token
 * que podremos usar para logarnos en Firebase
 *
 * @param {string} uid - UID del identificador del usuario
 * @return {object} - respuesta del servidor
 */
export async function createCustomToken(uid) {
  const result = await doRequest({
    method: 'post',
    url: 'v1/auth/create-token',
    data: {
      uid
    }
  })

  return result.data
}

/**
 * Registramos a un usuario en Firebase, lo que conlleva la creación
 * de una cuenta en el sistema de autenticaciones de firebase, así
 * como la creación de compañía, establecimiento y usuario en BD (firestore).
 * Por último se configura también el paquete
 *
 * @param {object} data - Objeto con todos los datos necesarios para el registro
 * @return {object} - respuesta del servidor
 */
export async function registerUser(data) {
  const result = await doRequest({
    method: 'post',
    url: 'v1/auth/register',
    data: {
      data
    }
  })

  return result.data
}

/**
 * Verificamos que el token del usuario indicado se encuentra
 * vigente en el servidor de Firebase
 *
 * @param {string} token - Objeto que deseamos verificar
 * @return {object} - respuesta del servidor
 */
export async function verifyIdToken(token) {
  const result = await doRequest({
    method: 'post',
    url: 'v1/auth/verify-token',
    data: {
      token
    }
  })

  return result.data
}
