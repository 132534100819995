// Constantes
import { ADDONS, PACKAGES } from '@/constants'
// Utils
import { get } from 'lodash'
// Vuex
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('place', ['placeData']),
    ...mapGetters('company', ['companyData']),
    /**
     * Obtiene las suscripciones de la compañía
     *
     * @return {Array}
     */
    companySubscriptions() {
      const subscriptions = get(this.companyData, 'subscriptions', [])
      return subscriptions.reduce((sumSubs, sub) => {
        const id = get(sub, 'subscriptable.id', null)
        if (id) {
          sumSubs.push(id)
        }
        return sumSubs
      }, [])
    },
    /**
     * Obtiene las suscripciones del establecimiento
     *
     * @return {Array}
     */
    placeSubscriptions() {
      const subscriptions = get(this.placeData, 'subscriptions', [])
      return subscriptions.reduce((sumSubs, sub) => {
        const id = get(sub, 'subscriptable.id', null)
        if (id) {
          sumSubs.push(id)
        }
        return sumSubs
      }, [])
    },
    /**
     * Obtiene las configuraciones de los "addons"
     * que los usuarios han generado en "placeData"
     *
     * @return {Object}
     */
    placeAddonsSetupByUser() {
      const addOnConfigs = get(this.placeData, 'addOnConfigs', [])

      return addOnConfigs.reduce((sumAddOns, addOn) => {
        sumAddOns[addOn.id] = { ...(addOn.configFields || {}) }
        return sumAddOns
      }, {})
    },
    /**
     * Obtiene las configuraciones de los "addons"
     * que los usuarios han generado en "companyData"
     *
     * @return {Object}
     */
    companyAddonsSetupByUser() {
      const addOnConfigs = get(this.companyData, 'addOnConfigs', [])

      return addOnConfigs.reduce((sumAddOns, addOn) => {
        sumAddOns[addOn.id] = { ...(addOn.configFields || {}) }
        return sumAddOns
      }, {})
    },
    /**
     * Obtiene los "addons" que las empresas y
     * los establecimientos tienen comprados
     *
     * @return {Array}
     */
    acquiredAddons() {
      return [
        ...this.companySubscriptions,
        ...this.placeSubscriptions,
        ...Object.keys(this.companyAddonsSetupByUser),
        ...Object.keys(this.placeAddonsSetupByUser)
      ]
    },
    /**
     * Tiene el paquete "Advance"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasAdvancePackage() {
      return this.acquiredAddons.indexOf(PACKAGES.advance.value) !== -1
    },
    /**
     * Tiene el paquete "Pro"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasProPackage() {
      return this.acquiredAddons.indexOf(PACKAGES.pro.value) !== -1
    },
    /**
     * Tiene el addons "Gestión Avanzada"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasAdvancedManagementAddon() {
      return this.acquiredAddons.indexOf(ADDONS.advancedManagement) !== -1
    },
    /**
     * Tiene el addons "Bakarta Pay"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasBakartaPayAddon() {
      return this.acquiredAddons.indexOf(ADDONS.bakartaPay) !== -1
    },
    /**
     * Tiene el addons "Bakarta Pay Place"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasBakartaPayPlaceAddon() {
      return this.acquiredAddons.indexOf(ADDONS.bakartaPayPlace) !== -1
    },
    /**
     * Tiene el addons de "Contact"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasContactAddon() {
      return this.acquiredAddons.indexOf(ADDONS.contact) !== -1
    },
    /**
     * Tiene el addons de "CRM"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasCRMAddon() {
      return this.acquiredAddons.indexOf(ADDONS.crm) !== -1
    },
    /**
     * Tiene el paquete "CTA"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasCTAAddon() {
      return this.acquiredAddons.indexOf(ADDONS.cta) !== -1
    },
    /**
     * Tiene el addons de "featuredItems"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasFeaturedItemsAddon() {
      return this.acquiredAddons.indexOf(ADDONS.featuredItems) !== -1
    },
    /**
     * Tiene el addons "imageGallery"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasImageGalleryAddon() {
      return this.acquiredAddons.indexOf(ADDONS.imageGallery) !== -1
    },
    /**
     * Tiene el addons "Gestión de usuarios"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasManageUsersAddon() {
      return this.acquiredAddons.indexOf(ADDONS.manageUsers) !== -1
    },
    /**
     * Tiene el addons "Horario de menú"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasMenusScheduleAddon() {
      return this.acquiredAddons.indexOf(ADDONS.menusSchedule) !== -1
    },
    /**
     * Tiene el addons "Gestión de multi-establecimientos"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasMultiPlacesAddon() {
      return this.acquiredAddons.indexOf(ADDONS.multiPlaces) !== -1
    },
    /**
     * Tiene el addons "Menú Premium"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasPremiumMenuAddon() {
      return this.acquiredAddons.indexOf(ADDONS.premiumMenu) !== -1
    },
    /**
     * Tiene el addons "PrintMenu"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasPrintMenuAddon() {
      return this.acquiredAddons.indexOf(ADDONS.printMenu) !== -1
    },
    /**
     * Tiene el addons "Take away"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasTakeAwayAddon() {
      return this.acquiredAddons.indexOf(ADDONS.takeAway) !== -1
    },
    /**
     * Tiene el addons del "TrackerCovid"
     *
     * @return {Boolean} - ¿posee addons?
     */
    hasTrackerCovidAddon() {
      return this.acquiredAddons.indexOf(ADDONS.trackerCovid) !== -1
    }
  }
}
