// Constants
import { ADDONS, ROLES } from '@/constants'

const FeaturedItemsByTabs = () => import('../views/FeaturedItemsByTabs')
const FeaturedItemsDetail = () => import('../views/FeaturedItemsDetail')

export default [
  {
    path: '/featured-items',
    name: 'featuredItems',
    component: FeaturedItemsByTabs,
    meta: {
      roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value],
      addons: [ADDONS.featuredItems]
    }
  },
  {
    path: '/featured-items/:id?',
    name: 'featuredItem',
    component: FeaturedItemsDetail,
    meta: {
      roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value],
      addons: [ADDONS.featuredItems]
    }
  }
]
