// Services
import { getPlaceById, updatePlaceById, updateAddOnConfigs } from '@/services/place'
import {
  cancelSubscriptionById,
  createSubscription,
  updateSubscriptionById,
  reactivateSubscriptionById
} from '@/services/subscription'
import { createUser, deleteUserById } from '@/services/user'
// Utils
import { get, isNil } from 'lodash'

export default {
  /**
   * Obtenemos datos del establecimiento del servidor
   * y almacenamos en local
   */
  getPlace: async ({ commit }, id) => {
    // Update place in Store
    const data = await getPlaceById(id)
    commit('setPlace', data)
  },

  /**
   * Update current place
   */
  updatePlace: async ({ commit }, data) => {
    // Update place in database
    await updatePlaceById(data)
    // Update place in Store
    commit('setPlace', data)
  },

  /**
   * Añade usuario al establecimiento
   *
   * @param {object} data - datos para la petición
   */
  addUserToPlace: async ({ commit, rootState }, data) => {
    // Usuarios del establecimiento
    const usersPlace = get(rootState, 'place.data.users', {})
    // Usuarios de la compañía
    const usersCompany = get(rootState, 'company.data.users', {})
    // Creamos usuario
    const result = await createUser(data)

    // Update place in Store
    commit('setPlace', {
      users: {
        ...usersPlace,
        [result.id]: true
      }
    })
    // Update company in Store
    commit(
      'company/setCompany',
      {
        users: {
          ...usersCompany,
          [result.id]: true
        }
      },
      { root: true }
    )
  },

  /**
   * Elimina usuario del establecimiento
   *
   * @param {string} id - UID del usuario a eliminar
   */
  deleteUserFromPlace: async ({ commit, rootState }, id) => {
    // Usuarios del establecimiento
    const usersPlace = get(rootState, 'place.data.users', {})
    // Usuarios de la compañía
    const usersCompany = get(rootState, 'company.data.users', {})
    // Eliminamos usuario
    const { ok } = await deleteUserById(id)

    if (ok) {
      // Update place in Store
      commit('setPlace', {
        users: Object.keys(usersPlace)
          .filter((user) => id !== user)
          .reduce((sumUsers, user) => {
            sumUsers[[user]] = true
            return sumUsers
          }, {})
      })
      // Update company in Store
      commit(
        'company/setCompany',
        {
          users: Object.keys(usersCompany)
            .filter((user) => id !== user)
            .reduce((sumUsers, user) => {
              sumUsers[[user]] = true
              return sumUsers
            }, {})
        },
        { root: true }
      )
    } else {
      throw new Error('Hubo un error al intentar eliminar el usuario')
    }
  },

  /**
   * Creamos una suscripción asociada al establecimiento
   *
   * @param {object} data - datos para la petición
   */
  createSubscriptionInPlace: async ({ dispatch }, data) => {
    const companyId = get(data, 'subscription.companyId', null)
    const placeId = get(data, 'subscription.placeId', null)
    // Creamos suscripción
    const { subscription } = await createSubscription(data)

    if (isNil(subscription)) {
      throw new Error('No se pudo completar el proceso de suscripción correctamente.')
    }

    // Actualizamos datos del establecimiento
    if (!isNil(placeId)) {
      await dispatch('getPlace', placeId)
    }

    // Actualizamos datos de la compañía
    if (!isNil(companyId)) {
      await dispatch('company/getCompany', companyId, { root: true })
    }

    return subscription
  },

  /**
   * Actualizamos una suscripción asociada al establecimiento
   *
   * @param {object} data - datos para la petición
   */
  updateSubscriptionInPlace: async ({ dispatch }, data) => {
    const placeId = get(data, 'subscription.placeId', null)
    // Actualizamos suscripción
    const { subscription } = await updateSubscriptionById(data)

    if (isNil(subscription)) {
      throw new Error('No se pudo completar el proceso de suscripción correctamente.')
    }

    // Actualizamos datos del establecimiento
    if (!isNil(placeId)) {
      await dispatch('getPlace', placeId)
    }

    return subscription
  },

  /**
   * Cancelamos una suscripción
   *
   * @param {object} data - datos para la petición
   */
  cancelSubscriptionInPlace: async ({ dispatch }, data) => {
    const { placeId, ...params } = data

    if (!isNil(params) && placeId) {
      const { ok } = await cancelSubscriptionById(params)

      if (!ok) {
        throw new Error(
          'No se pudo cancelar la suscripción. Póngase en contacto con su proveedor de servicio.'
        )
      }

      // Actualizamos datos del establecimiento
      if (!isNil(placeId)) {
        await dispatch('getPlace', placeId)
      }
    }
  },

  /**
   * Reactivamos una suscripción
   *
   * @param {object} data - datos para la petición
   */
  reactivateSubscriptionInPlace: async ({ dispatch }, data) => {
    const { placeId, stripeSubscriptionId = null, subscriptionId } = data

    if (subscriptionId && placeId) {
      const { ok } = await reactivateSubscriptionById({
        stripeSubscriptionId,
        subscriptionId
      })

      if (!ok) {
        throw new Error(
          'No se pudo reactivar la suscripción. Póngase en contacto con su proveedor de servicio.'
        )
      }

      // Actualizamos datos del establecimiento
      if (!isNil(placeId)) {
        await dispatch('getPlace', placeId)
      }
    }
  },

  /**
   * Actualizamos configuraciones del establecimiento
   *
   * @param {string} id - place UID
   * @param {object} addOns - options to save
   */
  updateConfigs: async ({ commit }, { id, addOns = [], setPlaceInLocal = true }) => {
    // Actualizamos datos en BD
    const results = await Promise.all(
      addOns.map(async (addOn) => {
        const result = await updateAddOnConfigs({ id, addOn })
        return result
      })
    )
    // Todas las llamadas funcionaron correctamente
    const everyIsOk = results.every((result) => result.ok)

    if (!everyIsOk) {
      throw new Error('Error al intentar almacenar los cambios')
    }

    // Actualizamos en Store
    if (setPlaceInLocal) {
      commit('setPlaceConfigs', addOns)
    }
  }
}
