// Services
import { updateCompanyById, getCompanyById, updateAddOnConfigs } from '@/services/company'

export default {
  /**
   * Obtenemos datos de la compañía del servidor
   * y almacenamos en local
   */
  getCompany: async ({ commit }, id) => {
    // Update place in Store
    const data = await getCompanyById(id)
    // Eliminamos los datos
    commit('resetCompany')
    // Establecemos los datos
    commit('setCompany', data)
  },

  /**
   * Update current company
   */
  updateCompany: async ({ commit }, data) => {
    // Update company in database
    await updateCompanyById(data)
    // Update company in Store
    commit('setCompany', data)
  },

  /**
   * Actualizamos configuraciones de la compañía
   *
   * @param {string} id - company UID
   * @param {object} addOns - options to save
   */
  updateConfigs: async ({ commit }, { id, addOns = [] }) => {
    // Actualizamos datos en BD
    const results = await Promise.all(
      addOns.map(async (addOn) => {
        const result = await updateAddOnConfigs({ id, addOn })
        return result
      })
    )
    // Todas las llamadas funcionaron correctamente
    const everyIsOk = results.every((result) => result.ok)

    if (!everyIsOk) {
      throw new Error('Error al intentar almacenar los cambios')
    }

    // Actualizamos en Store
    commit('setCompanyConfigs', addOns)
  }
}
