import { cloneDeep } from 'lodash'
import { get, set } from 'lodash'

export default {
  setCompany: (state, value) => (state.data = { ...state.data, ...value }),
  // Actualizamos las configuraciones de la compañía
  setCompanyConfigs: (state, value) => {
    value.forEach((addOn) => {
      const indexConfig = state.data.addOnConfigs.findIndex((config) => {
        return config.id === addOn.id
      })

      if (indexConfig > -1) {
        set(
          state.data,
          `addOnConfigs[${indexConfig}].configFields`,
          cloneDeep({
            ...get(state.data, `addOnConfigs[${indexConfig}].configFields`, {}),
            ...addOn.configFields
          })
        )
      }
    })
  },
  resetCompany: (state) => (state.data = null)
}
