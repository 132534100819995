import { get, isNil } from 'lodash'
import * as moment from 'moment'

export default {
  referredId: (state) => {
    const referredId = get(state, 'referred.id', null)
    const referredDate = get(state, 'referred.date', null)

    if (isNil(referredId) || isNil(referredDate)) {
      return null
    }

    const today = moment()
    const referredDateMoment = moment(referredDate)

    return referredDateMoment.diff(today, 'days') > 60 ? null : referredId
  },
  notificationsData: (state) => state.appNotifications.items
}
