// Constants
import { ADDONS } from '@/constants'
// utils
import { get } from 'lodash'

export default {
  brandData: (state) => {
    return { ...state.data }
  },
  // Configuración del addOn "company"
  brandDataConfig: (state, getters) => {
    const brandData = getters.brandData
    const addOnConfigs = get(brandData, 'addOnConfigs', [])
    const brandConfig = addOnConfigs.find((addon) => {
      return addon.id === ADDONS.company
    })

    return brandConfig && brandConfig.configFields ? brandConfig.configFields : {}
  }
}
