import state from './takeAway.state'
import mutations from './takeAway.mutations'
import actions from './takeAway.actions'
import getters from './takeAway.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
