import state from './intercom.state'
import mutations from './intercom.mutations'
import actions from './intercom.actions'
import getters from './intercom.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
