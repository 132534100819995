import Vue from 'vue'
import Router from 'vue-router'
// Vuex
import store from '@/store'
// Utils
import { get } from 'lodash'
// Routes
import routesName from './routesName'

Vue.use(Router)

const routes = routesName

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior() {
    // Always on top when change
    // the path into the app
    return { x: 0, y: 0 }
  }
})

/**
 * Handle user redirections
 */
router.beforeEach((to, from, next) => {
  // store data
  const user = get(store, 'state.authentication.user', false)
  const company = get(store, 'state.company.data.addOnConfigs', [])
  const place = get(store, 'state.place.data.addOnConfigs', [])
  const addons = [...company, ...place].map((addon) => {
    return addon.id
  })
  // url data
  const isPublic = get(to, 'meta.isPublic', false)
  const isAnAddon = get(to, 'meta.addons', false)
  const isAnRoles = get(to, 'meta.roles', false)

  // Access conditons
  const anonymousUserCantAccess = !isPublic && !user
  const addonsUserCantAccess =
    isAnAddon !== false &&
    !isAnAddon.every((addon) => {
      return addons.indexOf(addon) > -1
    })
  const rolesUserCantAccess = user && isAnRoles && isAnRoles.indexOf(user.role) === -1

  if (isPublic) {
    // Zona pública
    next()
  } else if (anonymousUserCantAccess || addonsUserCantAccess || rolesUserCantAccess) {
    // Almacenamos redirección en caso de ser anónimo
    // intentando acceder a una sección restringida
    if (anonymousUserCantAccess) {
      store.commit('app/setUrlRedirect', to.path)
    }
    next({ path: '/login' }) // A login
  } else {
    // Usuario tiene permisos y puede pasar
    next()
  }
})

export default router
