// Request
import { doRequest } from '@/utils/requestApi'
// Utils
import { isNil, get } from 'lodash'
// Vuex
import store from '@/store'

/**
 * Get categories by parentId
 *
 * @param {string} parentId - UID category in database
 */
export async function getCategoriesByParentId(parentId) {
  // UID del item personalizado (placeId en este caso)
  const customItemId = get(store, 'getters.place/placeData.id', null)
  const result = await doRequest({
    method: 'get',
    url: isNil(customItemId)
      ? `v1/category/childs/${parentId}`
      : `v1/category/childs/${parentId}/${customItemId}`
  })

  return result.data
}
/**
 * Get all categories by parentIds
 *
 * @param {Array} parentIds - UID category in database
 */
export async function getEveryCategoriesByParentIds(parentIds) {
  if (Array.isArray(parentIds)) {
    const allCategories = await Promise.all(
      parentIds.map(async (id) => {
        return await getCategoriesByParentId(id)
      })
    )

    // "aplanamos" los arrays
    return allCategories.reduce((sumCategories, category) => {
      sumCategories.push(...category)
      return sumCategories
    }, [])
  }
  return []
}
/**
 * Obtenemos el padre (carta) menú de la categoría indicada
 *
 * @param {string} id - UID category in database
 * @return {object | null}
 */
export async function getParentCategoryByChildId(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/category/parent/${id}`
  })

  return result.data
}
/**
 * Create new category
 *
 * @param {Object} data - category data
 * @param {Object} options - opciones de guardado (traducciones...)
 */
export async function createCategory(data, id = null, options = {}) {
  const result = await doRequest({
    method: 'post',
    url: isNil(id) ? 'v1/category' : `v1/category/${id}`,
    data: {
      data,
      options
    }
  })

  return result.data
}

/**
 * Update category in database
 *
 * @param {Object} data - category data
 * @param {Object} options - opciones de guardado (traducciones...)
 */
export async function updateCategoryById(data, options = {}) {
  const { id, ...params } = data
  // UID del item personalizado (placeId en este caso)
  const customItemId = get(store, 'getters.place/placeData.id', null)
  const result = await doRequest({
    method: 'patch',
    url: `v1/category/${id}`,
    data: {
      data: params,
      options: { ...options, customItemId }
    }
  })

  return result.data
}

/**
 * Get category by UID
 *
 * @param {string} id - UID category in database
 */
export async function getCategoryById(id) {
  // UID del item personalizado (placeId en este caso)
  const customItemId = get(store, 'getters.place/placeData.id', null)
  const result = await doRequest({
    method: 'get',
    url: isNil(customItemId) ? `v1/category/${id}` : `v1/category/${id}/${customItemId}`
  })

  return result.data
}

/**
 * Obtenemos todos los menus/cartas dado el id del establecimiento
 *
 * @param {String} id - UID place in database
 */
export async function getEveryMenusByPlaceId(id) {
  // UID de la compañía (marca)
  const brandId = get(store, 'getters.brand/brandData.id', null)
  const result = await doRequest({
    method: 'get',
    url: isNil(brandId)
      ? `v1/category/menus/place/${id}`
      : `v1/category/menus/place/${id}/${brandId}`
  })

  return result.data
}

/**
 * Obtiene todas las categorías y platos asociados a un menú o carta
 *
 * @param {string} id - UID category in database
 */
export async function getAvailableCategoriesAndProductsByCategoryId(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/category/every/${id}`
  })

  return result.data
}

/**
 * Remove the category by id
 *
 * @param {string} id - UID category in database
 */
export async function deleteCategoryById(id) {
  const result = await doRequest({
    method: 'delete',
    url: `v1/category/${id}`
  })

  return result.data
}

/**
 * Remove all child items by parentId and dishes associated
 *
 * @param {string} parentId - UID category in database
 */
export async function deleteEveryCategoriesAndDishesByCategoryId(parentId) {
  const result = await doRequest({
    method: 'delete',
    url: `v1/category/every/${parentId}`
  })

  return result.data
}
