// Constants
import { ADDONS, ROLES } from '@/constants'

const MultiPlacesList = () => import('../views/MultiPlacesList')
const MultiPlacesFusion = () => import('../views/MultiPlacesFusion')
const MultiPlacesCreate = () => import('../views/MultiPlacesCreate')
const MultiPlacesClone = () => import('../views/MultiPlacesClone')
const MultiPlacesEndpoint = () => import('../views/MultiPlacesEndpoint')

export default [
  {
    path: '/multi-places',
    name: 'multiPlacesList',
    component: MultiPlacesList,
    meta: {
      roles: [ROLES.horeca_admin.value],
      addons: [ADDONS.multiPlaces]
    }
  },
  {
    path: '/multi-places-create',
    name: 'multiPlacesCreate',
    component: MultiPlacesCreate,
    meta: {
      roles: [ROLES.horeca_admin.value],
      addons: [ADDONS.multiPlaces]
    }
  },
  {
    path: '/multi-places-fusion',
    name: 'multiPlacesFusion',
    component: MultiPlacesFusion,
    meta: {
      roles: [ROLES.horeca_admin.value],
      addons: [ADDONS.multiPlaces]
    }
  },
  {
    path: '/multi-places-clone',
    name: 'multiPlacesClone',
    component: MultiPlacesClone,
    meta: {
      roles: [ROLES.horeca_admin.value],
      addons: [ADDONS.multiPlaces]
    }
  },
  {
    path: '/multi-places-endpoint/:key',
    name: 'multiPlacesEndpoint',
    component: MultiPlacesEndpoint,
    meta: {
      roles: [ROLES.horeca_admin.value],
      addons: [ADDONS.multiPlaces]
    }
  }
]
