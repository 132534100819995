// Constants
import { ADDONS, ROLES } from '@/constants'

const AdvancedManagementProductsCloningForm = () =>
  import('../views/AdvancedManagementProductsCloningForm')

const AdvancedManagementProductsPricesForm = () =>
  import('../views/AdvancedManagementProductsPricesForm')

export default [
  {
    path: '/advanced-management-products-cloning-form/:targetCategory/:sourceCategory/:type',
    name: 'advancedManagementProductsCloningForm',
    component: AdvancedManagementProductsCloningForm,
    meta: {
      roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value],
      addons: [ADDONS.advancedManagement]
    }
  },
  {
    path: '/advanced-management-products-prices-form/:sourceCategory/:type',
    name: 'advancedManagementProductsPricesForm',
    component: AdvancedManagementProductsPricesForm,
    meta: {
      roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value],
      addons: [ADDONS.advancedManagement]
    }
  }
]
