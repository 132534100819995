import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// contants
import { VUEX_PERSISTED_KEY } from '@/constants'
// Vuex modules
import authentication from './authentication'
import app from './app'
import brand from './brand'
import place from './place'
import company from './company'
import config from './config'
import meta from './meta'
import intercom from './intercom'
// Addons
import takeAway from '@/addons/takeAway/store/takeAway'

Vue.use(Vuex)

/* If you don't know about Vuex, please refer to https://vuex.vuejs.org/ */

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    createPersistedState({
      key: VUEX_PERSISTED_KEY,
      paths: [
        'app.referred',
        'app.selectedPlace',
        'app.urlRedirect',
        'authentication.user',
        'authentication.onboarding',
        'brand',
        'company',
        'config',
        'meta',
        'place'
      ]
    })
  ],
  modules: {
    authentication,
    app,
    brand,
    place,
    company,
    config,
    meta,
    intercom,
    takeAway
  }
})
