// Constants
import { ADDONS, ADDON_TYPES, PACKAGES } from '@/constants'
// utils
import { isNil, get } from 'lodash'

export default {
  // Idiomas adicionales
  additionalLanguages: (state) => {
    const addOnConfigs = get(state, 'data.addOnConfigs', [])

    if (addOnConfigs.length === 0) return false

    const placeConfig = addOnConfigs.find((addon) => {
      return addon.id === ADDONS.place
    })

    if (isNil(placeConfig)) return false

    const additionalLanguages = get(placeConfig, 'configFields.additionalLanguages', [])

    return additionalLanguages
  },
  // ¿hay más de un idioma adicional?
  areThereAdditionalLanguages: (state, getters) => {
    return Array.isArray(getters.additionalLanguages)
      ? getters.additionalLanguages.length > 0
      : false
  },
  // Moneda
  currencyData: (state) => {
    const addOnConfigs = get(state, 'data.addOnConfigs', [])

    if (addOnConfigs.length === 0) return null

    const placeConfig = addOnConfigs.find((addon) => {
      return addon.id === ADDONS.place
    })

    if (isNil(placeConfig)) return null

    return get(placeConfig, 'configFields.currency', null)
  },
  // Datos del establecimiento
  placeData: (state) => {
    return { ...state.data }
  },
  // Paquetes (planes) suscritos del establecimiento
  subscribedPackages(state, getters) {
    const placeData = getters.placeData

    // Planes (paquetes) del establecimiento
    return get(placeData, 'subscriptions', [])
      .filter((subscription) => {
        return (
          subscription.subscriptable.type === ADDON_TYPES.package.value &&
          subscription.subscriptable.id !== PACKAGES.free.value
        )
      })
      .map((subscription) => {
        return {
          active: subscription.active,
          id: subscription.id,
          packageId: subscription.subscriptable.id,
          periodFrom: subscription.periodFrom,
          periodTo: subscription.periodTo,
          priceId: subscription.priceId,
          stripePriceId: subscription.stripePriceId,
          stripeSubscriptionId: subscription.stripeSubscriptionId
        }
      })
  }
}
