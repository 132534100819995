import { get } from 'lodash'

export default {
  // Datos del usuario
  userData: (state) => {
    return { ...state.user }
  },
  // Estado de las secciones de onboarding
  onboardingSections: (state) => {
    // Secciones onboarding
    const onboardingSections = get(state, 'onboarding', [])
    // Proceso onboarding del usuario
    const userOnboarding = get(state, 'user.onboarding', {})

    if (Object.keys(userOnboarding).length === 0) {
      return false
    }

    // Devolvemos las secciones que están completadas y la actual
    return onboardingSections.map((section, index, data) => {
      const current =
        (index === 0 && !userOnboarding[section.id]) ||
        (index > 0 && userOnboarding[data[index - 1].id] && !userOnboarding[section.id])
          ? true
          : false
      return {
        ...section,
        completed: userOnboarding[section.id],
        current
      }
    })
  },
  // ¿Fue el onboarding completado?
  onboardingCompleted: (state, getters) => {
    const onboardingSections = getters.onboardingSections

    return onboardingSections && onboardingSections.length > 0
      ? onboardingSections.every((boarding) => boarding.completed)
      : true
  }
}
