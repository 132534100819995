// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Get brand company by brand id
 *
 * @param {string} brand - UID brand
 * @param {object} options - options to get data
 */
export async function getBrandCompanyByBrandId(
  brand,
  options = { includeAddOnsConfigs: true, includeSubscriptions: true }
) {
  const result = await doRequest({
    method: 'post',
    url: `v1/company/brand/${brand}`,
    data: {
      options
    }
  })

  return result.data
}

/**
 * Get company by UID
 *
 * @param {string} id - UID company in database
 * @param {object} options - options to get data
 */
export async function getCompanyById(
  id,
  options = { includeAddOnsConfigs: true, includeSubscriptions: true }
) {
  const result = await doRequest({
    method: 'post',
    url: `v1/company/${id}`,
    data: {
      options
    }
  })

  return result.data
}

/**
 * Update company in database
 *
 * @param {Object} data - company data
 */
export async function updateCompanyById(data) {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/company/${id}`,
    data: {
      ...params
    }
  })

  return result.data
}

/**
 * Actualizar configuraciones de la compañía
 *
 * @param {Object} data - datos petición
 * @return {object}
 */
export async function updateAddOnConfigs(data) {
  const { id, addOn } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/company/add-on-configs/${id}`,
    data: {
      addOn
    }
  })

  return result.data
}

/**
 * Obtenemos los movimientos del wallet
 *
 * @param {string} id - id de la compañía
 * @return {object}
 */
export async function getWalletTransactions(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/company/wallet/transactions/${id}`
  })

  return result.data
}

/**
 * Función para "pedir" que se le "pague" el saldo
 * al usuario de la compañía a través de PayPal
 *
 * @param {object} data - datos a enviar
 * @return {object}
 */
export async function moveWalletBalanceToPaypal(data) {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'post',
    url: `v1/company/wallet/request/${id}`,
    data: {
      data: params
    }
  })

  return result.data
}
