// Constants
import { BRANDS, ROLES, COMPANY_RELATIONSHIP_TYPES } from '@/constants'
// Services
import {
  getIdToken,
  signInWithEmailAndPassword,
  updateProfile,
  updatePassword,
  updateEmail,
  signOut
} from '@/firebase/authentication'
import { registerUser } from '@/services/authentication'
import { updateUserById, getUserById } from '@/services/user'
import { getCompanyById } from '@/services/company'
import { getPlaceById } from '@/services/place'
import { acceptRelationship, createRelationship } from '@/services/relationships'
// Utils
import { getCurrentBrand, getEnvironmentVariable } from '@/utils'
import { createSessionCookie, removeSessionCookie } from '@/utils/session-cookie'
import { get, isNil } from 'lodash'

export default {
  /**
   * Callback fired when user login
   */
  login: async ({ commit, dispatch, rootState }, { email, password }) => {
    // Roles permitidos para logar
    const allowedRoles = Object.keys(ROLES).filter(
      (value) => value !== ROLES.partner_brand.value && value !== ROLES.partner_partner.value
    )
    // Login user
    const userLogged = await signInWithEmailAndPassword(email, password)
    // Token del usuario logado
    const idToken = await getIdToken()

    // Establecemos la cookie de sesión para las futuras peticiones a la API
    await createSessionCookie(idToken)

    // Get data from Database and set in Store
    const user = await getUserById(userLogged.user.uid)
    const companyKey = Object.keys(user.companies)[0]
    const placesKeys = Object.keys(user.places)
    // Seleccionamos el establecimiento seleccionado en la última ocasión
    const selectedPlace = get(rootState, 'app.selectedPlace', null)
    const currentSelectedPlace =
      placesKeys.indexOf(selectedPlace) > -1 ? selectedPlace : placesKeys[0]

    // Comprueba si el usuario se está logando en la
    // aplicación correcta.
    if (
      getCurrentBrand(user.brand) !== getEnvironmentVariable('VUE_APP_BRAND') ||
      allowedRoles.indexOf(user.role) === -1
    ) {
      // logout
      await signOut()
      // Mensaje de error
      if (getCurrentBrand(user.brand) !== getEnvironmentVariable('VUE_APP_BRAND')) {
        throw new Error(
          `Su usuario no pertenece a este dominio. Pulse <a href="${
            BRANDS[getCurrentBrand(user.brand)].urlHoreca
          }"><b>AQUÍ</b></a> para re-intentar el acceso.`
        )
      }
      // Mensaje de error
      if (allowedRoles.indexOf(user.role) === -1) {
        throw new Error('El usuario/email indicado no posee el ROL correcto.')
      }
    }

    // Establecemos datos en local de la "marca" (brand)
    await dispatch(
      'brand/getBrand',
      {
        brand: getEnvironmentVariable('VUE_APP_BRAND')
      },
      {
        root: true
      }
    )
    // Establecemos datos en local de la compañía
    await dispatch('company/getCompany', companyKey, {
      root: true
    })
    // Establecemos datos en local del establecimiento
    await dispatch('place/getPlace', currentSelectedPlace, {
      root: true
    })
    // Set selectedPlace
    commit('app/setSelectedPlace', currentSelectedPlace, {
      root: true
    })
    // Establecemos datos en local del usuario
    commit('setUser', {
      ...user,
      name: userLogged.user.displayName,
      email: userLogged.user.email
    })
    // Ocultamos loading si llegamos del registro
    commit(
      'app/setAppLoadingLayer',
      {
        visible: false
      },
      { root: true }
    )
  },

  /**
   * "Logamos" a los usuarios "brand" (de la marca) asociados al establecimiento
   */
  loginBrand: async ({ commit, dispatch }, { placeId, userBrandId }) => {
    // Algunos de los datos requeridos faltan
    if (isNil(placeId) || isNil(userBrandId)) {
      throw new Error('Datos de acceso insuficientes.')
    }

    // Roles permitidos para el acceso de los usuarios
    const allowedRoles = Object.keys(ROLES).filter(
      (value) => value === ROLES.brand_admin.value || value === ROLES.brand_admin.value
    )
    // Obtenemos los datos del establecimiento
    const place = await getPlaceById(placeId)
    // Obtenemos los datos del usuario a logar
    const userBrand = await getUserById(userBrandId)

    // El usuario que intenta acceder es del rol adecuado
    if (allowedRoles.indexOf(userBrand.role) === -1) {
      throw new Error('No posees el rol adecuado para acceder.')
    }

    // El usuario que intenta acceder es de la compañía "madre"
    if (isNil(userBrand.brand) || userBrand.brand !== place.brand) {
      throw new Error('No perteneces a la "marca madre" de este establecimiento.')
    }

    // Comprobamos que el establecimiento tiene usuarios asociados
    if (isNil(place.users) || Object.keys(place.users).length === 0) {
      throw new Error('No existen usuarios asociados al establecimiento.')
    }

    // Obtenemos todos los usuarios asociados al establecimiento
    const placeUsers = await Promise.all(
      Object.keys(place.users).map(async (userId) => {
        const user = await getUserById(userId)
        return user
      })
    )

    // Nos quedamos con el usuario con rol "horeca_admin"
    const userHorecaAdmin = placeUsers.find((user) => {
      return user.role === ROLES.horeca_admin.value
    })

    // Comprobamos que existe un usuario "horeca_admin"
    // en la compañía que tenemos asociada
    if (isNil(userHorecaAdmin)) {
      throw new Error(
        'No existe un usuario con privilegios de gestor en el establecimiento indicado.'
      )
    }

    // Establecemos datos en local de la "marca" (brand)
    await dispatch('brand/getBrand', getEnvironmentVariable('VUE_APP_BRAND'), {
      root: true
    })
    // Establecemos datos de la compañía en local
    await dispatch('company/getCompany', Object.keys(place.companies)[0], {
      root: true
    })
    // Establecemos datos del establecimiento en local
    commit('place/setPlace', place, {
      root: true
    })
    // Establecemos los datos del usuario en local
    commit('setUser', {
      ...userHorecaAdmin,
      isManagerUser: true
    })
  },

  /**
   * "Logamos" a los usuarios "partner", dentro de la aplicación
   * como el gestor de la compañía (company_manager)
   */
  loginPartner: async ({ commit, dispatch }, { companyId, userPartnerId }) => {
    // Algunos de los datos requeridos faltan
    if (isNil(companyId) || isNil(userPartnerId)) {
      throw new Error('Datos de acceso insuficientes.')
    }

    // Roles permitidos para el acceso de los usuarios
    const allowedRoles = Object.keys(ROLES).filter(
      (value) => value === ROLES.partner_brand.value || value === ROLES.partner_partner.value
    )
    // Obtenemos los datos de la compañía (hija)
    const company = await getCompanyById(companyId)
    // Obtenemos los datos del usuario (partner)
    const userPartner = await getUserById(userPartnerId)
    // Compañía del usuario partner que loga
    const companyPartnerId = Object.keys(userPartner.companies)[0]

    // Comprobamos que el usuario que intenta acceder es "partner"
    if (allowedRoles.indexOf(userPartner.role) === -1) {
      throw new Error('No posees el rol adecuado para acceder.')
    }

    // Comprobamos si las compañías están asociadas como
    // con rol de "Gestionada" (partner)
    if (isNil(company.partnerId) || company.partnerId !== companyPartnerId) {
      throw new Error('No posees relación con la compañía indicada.')
    }

    // Comprobamos que la compañía tiene usuarios asociados
    if (isNil(company.users) || Object.keys(company.users).length === 0) {
      throw new Error('No existen usuarios asociados a la compañía.')
    }

    // Obtenemos todos los usuarios que pertenecen a la
    // compañía que queremos gestionar
    const companyUsers = await Promise.all(
      Object.keys(company.users).map(async (userId) => {
        const user = await getUserById(userId)
        return user
      })
    )

    // Nos quedamos con el usuario con rol "horeca_admin"
    const userHorecaAdmin = companyUsers.find((user) => {
      return user.role === ROLES.horeca_admin.value
    })

    // Comprobamos que existe un usuario "horeca_admin"
    // en la compañía que tenemos asociada
    if (isNil(userHorecaAdmin)) {
      throw new Error('No existe un usuario con privilegios de gestor en la compañía indicada.')
    }

    // Establecemos datos en local de la "marca" (brand)
    await dispatch('brand/getBrand', getEnvironmentVariable('VUE_APP_BRAND'), {
      root: true
    })
    // Establecemos datos del establecimiento en local
    await dispatch('place/getPlace', Object.keys(company.places)[0], {
      root: true
    })
    // Establecemos datos de compañía, a gestionar, en local
    commit('company/setCompany', company, {
      root: true
    })
    // Establecemos los datos del usuario "company_manager"
    commit('setUser', {
      ...userHorecaAdmin,
      isManagerUser: true
    })
  },

  /**
   * Callback fired when user logout
   */
  logout: ({ commit }) => {
    // Eliminamos cookie de sesión
    removeSessionCookie()
    // Reset locale store
    commit('company/resetCompany', null, { root: true })
    commit('place/resetPlace', null, { root: true })
    commit('config/resetAllergens', null, { root: true })
    commit('config/resetLanguages', null, { root: true })
    commit('meta/resetRations', null, { root: true })
    commit('app/resetAppDialog', null, { root: true })
    commit('app/resetAppLoadingLayer', null, { root: true })
    commit('app/resetAppNotifications', null, { root: true })
    commit('takeAway/resetTakeAwayOrders', null, { root: true })
    commit('takeAway/resetTakeAwayPreOrders', null, { root: true })
    commit('takeAway/setHasTakeAwayMenu', null, { root: true })
    commit('resetUser')
  },

  /**
   * Update user authentication (firebase authentication)
   */
  updateUserAuth: async ({ commit }, { name, email, password, changePassword }) => {
    // Update password
    if (changePassword) {
      await updatePassword(password)
    }
    // Update user data
    await updateProfile({ displayName: name })
    // Update user email
    await updateEmail(email)
    // Set Store
    commit('setUser', { name, email })
  },

  /**
   * Obtenemos datos del usuario del servidor
   * y almacenamos en local
   */
  getUser: async ({ commit }, id) => {
    const data = await getUserById(id)
    commit('setUser', data)
  },

  /**
   * Update user (firebase database)
   */
  updateUser: async ({ commit }, data) => {
    // Update users
    await updateUserById(data)
    // Set Store
    commit('setUser', data)
  },

  /**
   * Se crea usuario, compañía y establecimiento en BD
   */
  registerNewUser: async ({ dispatch, commit }, data) => {
    // Mensajes para el usuario no se me aburra
    commit(
      'app/setAppLoadingLayer',
      {
        text: 'Configurando su nuevo usuario. Espere por favor... 1/2',
        visible: true
      },
      { root: true }
    )

    // Todo el proceso de creación se lleva en el servidor
    const { companyId } = await registerUser(data)

    // Otros acciones propias de la lógica del registro
    // en esta vista (registro desde el panel HORECA)
    const { company, user } = data

    // Creamos relación entre compañías (branding o referred)
    if (!isNil(company.brandId) || !isNil(company.referredId)) {
      // Se trata de una relación de tipo "brand"?
      const isBrand = !isNil(company.brandId)
      // Identificador de compañía
      const companyIdRelate = isBrand ? company.brandId : company.referredId

      // Creamos relación
      const { relationshipId } = await createRelationship(companyIdRelate, companyId, {
        sendEmail: false,
        type: isBrand ? COMPANY_RELATIONSHIP_TYPES.brand : COMPANY_RELATIONSHIP_TYPES.referred
      })
      // Confirmamos relación
      const ok = await acceptRelationship(relationshipId)

      if (!ok) {
        throw new Error('No se pudo relacionar la compañía creada.')
      }
    }

    // Mensajes para el usuario no se me aburra
    commit(
      'app/setAppLoadingLayer',
      {
        text: 'Preparando primer acceso al panel. Espere por favor... 2/2',
        visible: true
      },
      { root: true }
    )

    // Tras el registro, pasamos al login del usuario
    await dispatch('login', {
      email: user.email,
      password: user.password
    })
  }
}
