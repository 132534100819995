<template>
  <svg
    id="gallery-icon"
    enable-background="new 0 0 500.15 500.15"
    height="512"
    viewBox="0 0 500.15 500.15"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <path
          d="m203.29 325.597h-155.792c-26.233 0-47.498-21.266-47.498-47.498v-138.218c0-26.232 21.265-47.498 47.498-47.498h40.088l5.367-13.798c5.226-13.8 18.492-22.887 33.248-22.775h59.372c14.764-.091 28.026 9.013 33.248 22.823l5.343 13.751h39.922c26.232 0 47.498 21.265 47.498 47.498v78.846c0 6.558-5.316 11.874-11.874 11.874s-11.874-5.316-11.874-11.874v-78.846c0-13.116-10.633-23.749-23.749-23.749h-48.21c-4.898-.001-9.294-3.01-11.067-7.576l-8.312-21.374c-1.718-4.595-6.114-7.636-11.019-7.623h-59.372c-4.887-.017-9.271 3.003-10.996 7.576l-8.336 21.374c-1.758 4.585-6.157 7.615-11.067 7.623h-48.21c-13.116 0-23.749 10.633-23.749 23.749v138.218c0 13.116 10.633 23.749 23.749 23.749h155.792c6.558 0 11.874 5.316 11.874 11.874 0 6.557-5.316 11.874-11.874 11.874z"
        />
        <path
          d="m155.792 268.243c-32.79.079-59.436-26.439-59.514-59.229-.079-32.79 26.439-59.436 59.229-59.514 32.79-.079 59.436 26.439 59.514 59.229v.142c.001 32.735-26.494 59.294-59.229 59.372zm0-94.757c-19.674-.079-35.687 15.807-35.765 35.481-.079 19.674 15.807 35.687 35.481 35.765 19.674.079 35.687-15.807 35.765-35.481 0-.087 0-.174 0-.261-.065-19.572-15.908-35.426-35.481-35.504z"
        />
        <path
          d="m172.963 125.181h-34.341c-6.558 0-11.874-5.316-11.874-11.874s5.316-11.874 11.874-11.874h34.341c6.558 0 11.874 5.316 11.874 11.874s-5.316 11.874-11.874 11.874z"
        />
        <path
          d="m488.276 444.341h-237.488c-6.558 0-11.874-5.316-11.874-11.874v-213.74c0-6.558 5.316-11.874 11.874-11.874h237.488c6.558 0 11.874 5.316 11.874 11.874v213.739c0 6.559-5.316 11.875-11.874 11.875zm-225.614-23.749h213.739v-189.99h-213.739z"
        />
        <path
          d="m250.788 418.383c-6.558-.014-11.863-5.341-11.849-11.899.007-3.193 1.299-6.248 3.585-8.477l71.246-69.062c3.62-3.502 9.055-4.34 13.561-2.09l38.117 18.999 41.703-62.531c3.644-5.453 11.018-6.919 16.47-3.275.663.443 1.279.951 1.84 1.518l71.246 72.054c4.278 4.971 3.716 12.468-1.254 16.746-4.506 3.878-11.186 3.826-15.631-.122l-61.011-61.747-39.399 59.135c-3.329 4.98-9.846 6.701-15.199 4.014l-39.969-19.973-65.191 63.172c-2.185 2.217-5.153 3.488-8.265 3.538z"
        />
        <circle cx="345.783" cy="289.997" r="11.827" />
        <circle cx="244.138" cy="153.964" r="11.827" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'GalleryIcon'
}
</script>
