// Services
import { signOut } from '@/firebase/authentication'
// Mixin
import intercomMixin from './intercomMixin'
// Utils
import { isEqual } from 'lodash'

export default {
  mixins: [intercomMixin],
  methods: {
    /**
     * Clean every session user data
     */
    async closeSession() {
      // intercom reset user data
      this.intercomShutdown()
      // Cerramos sesión
      await signOut()
    },

    /**
     * Modify app alert layer
     *
     * @param {Object} params - alert options
     */
    modifyAppAlert(params) {
      this.$store.commit('app/setAppAlert', params)
    },

    /**
     * Modify app dialog
     *
     * @param {Object} params - dialog options
     */
    modifyAppDialog(params) {
      this.$store.commit('app/setAppDialog', params)
    },

    /**
     * Modify app loading layer
     *
     * @param {Object} params - loading layer options
     */
    modifyAppLoadingLayer(params) {
      this.$store.commit('app/setAppLoadingLayer', params)
    },

    /**
     * Move us to differents position into nav history
     *
     * @param {Number} steps - "steps" number that we want
     * move the history to forward (positive value)
     * or back (negative value)
     */
    routerGoTo(steps = -1) {
      this.$router.go(steps)
    },

    /**
     * We navigate to ...
     *
     * @param {Object} data - https://router.vuejs.org/guide/essentials/navigation.html
     */
    routerPushTo(data) {
      const currentPath = this.$route
      let isSamePath = false

      if (typeof data === 'string') {
        isSamePath = currentPath.path === data
      } else if (typeof data === 'object') {
        isSamePath = Object.entries(data).every((item) => {
          return typeof item[1] === 'string' ||
            typeof item[1] === 'number' ||
            typeof item[1] === 'boolean'
            ? item[1] === currentPath[item[0]]
            : isEqual(item[1], currentPath[item[0]])
        })
      }

      if (!isSamePath) {
        this.$router.push(data)
      }
    },

    /**
     * We navigate to ... (without history nav)
     *
     * @param {Object} data - https://router.vuejs.org/guide/essentials/navigation.html
     */
    routerReplaceTo(data) {
      const currentPath = this.$route
      let isSamePath = false

      if (typeof data === 'string') {
        isSamePath = currentPath.path === data
      } else if (typeof data === 'object') {
        isSamePath = Object.entries(data).every((item) => {
          return item[1] === currentPath[item[0]]
        })
      }

      if (!isSamePath) {
        this.$router.replace(data)
      }
    }
  }
}
