// Constants
import { ADDONS, ROLES } from '@/constants'

const BakartaPayByTabs = () => import('../views/BakartaPayByTabs')

export default [
  {
    path: '/bakarta-pay',
    name: 'bakartaPay',
    component: BakartaPayByTabs,
    meta: {
      roles: [ROLES.horeca_admin.value],
      addons: [ADDONS.bakartaPay]
    }
  }
]
