import store from '@/store'

/**
 * Establecemos el parámetro en vuex
 */
function setUserActivity() {
  store.commit('app/setUserActivity', true)
  removeEventsListener()
}

/**
 * Eliminamos los eventos de escucha para detectar
 * la actividad del usuario
 */
function removeEventsListener() {
  // document.body.removeEventListener('mousemove', setUserActivity)
  document.body.removeEventListener('scroll', setUserActivity)
  document.body.removeEventListener('keydown', setUserActivity)
  document.body.removeEventListener('click', setUserActivity)
}
/**
 * Añadimos los eventos de escucha para detectar
 * la actividad del usuario
 */
function setEventsListener() {
  // document.body.addEventListener('mousemove', setUserActivity)
  document.body.addEventListener('scroll', setUserActivity)
  document.body.addEventListener('keydown', setUserActivity)
  document.body.addEventListener('click', setUserActivity)
}

// Lanzamos escucha de eventos
setEventsListener()
