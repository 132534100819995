<template>
  <v-app class="app" data-cy="app">
    <!-- Loading -->
    <AppLoadingLayer />
    <!-- Alert -->
    <AppAlert />
    <!-- Dialog -->
    <AppDialog />
    <!-- Content -->
    <router-view></router-view>
    <!-- Print -->
    <PrintVersion />
    <!-- Versión -->
    <span id="appVersion">{{ appVersion }}</span>
  </v-app>
</template>

<script>
// Components
import AppAlert from '@/components/elements/store/AppAlert'
import AppDialog from '@/components/elements/store/AppDialog'
import AppLoadingLayer from '@/components/elements/store/AppLoadingLayer'
import PrintVersion from '@/components/ui/PrintVersion'
// Mixins
import authMixin from '@/mixins/authMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapMutations } from 'vuex'
// Google
import { loadGoogleMap } from '@/services/googleMap'
// Utils
import { refreshSessionCookie } from '@/utils/session-cookie'

// Version
const version = require('../package.json').version

export default {
  name: 'App',
  components: { AppAlert, AppDialog, AppLoadingLayer, PrintVersion },
  mixins: [authMixin, uiMixin],
  data: function () {
    return {
      intervalSession: null // Para el loop de las sesiones
    }
  },
  computed: {
    /**
     * Current versión App
     *
     * @return {String}
     */
    appVersion() {
      return version
    }
  },
  watch: {
    // Se cambia la resolución de la pantalla
    '$vuetify.breakpoint.name': {
      handler(value) {
        // Large device
        this.setLargeDevice(value === 'lg' || value === 'xl')
        // Medium device
        this.setMediumDevice(value === 'md')
        // Small device
        this.setSmallDevice(value === 'sm')
        // Extra Small device
        this.setExtraSmallDevice(value === 'xs')
      },
      immediate: true
    }
  },
  async created() {
    // Cargamos librerías de GoogleMaps
    loadGoogleMap(
      {
        libraries: ['places', 'visualization']
      },
      0
    )
    // Iniciamos "interval" para mantener la sesión viva (5 minutos)
    this.intervalSession = setInterval(async () => await this.refreshCookie(), 300000)
  },
  beforeDestroy() {
    // Matamos "interval" de sesiones
    clearInterval(this.intervalSession)
  },
  methods: {
    ...mapMutations('app', [
      'setExtraSmallDevice',
      'setMediumDevice',
      'setLargeDevice',
      'setSmallDevice'
    ]),
    /**
     * Refrescamos la cookie se sesión
     */
    async refreshCookie() {
      await refreshSessionCookie()
    }
  }
}
</script>
<style lang="scss" scoped>
.app {
  background-color: #fff;
  #appVersion {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    font-size: 0.65rem;
    line-height: 0.65rem;
    color: rgba(#000, 0.5);
    padding: 0.5rem;
  }
}
</style>
