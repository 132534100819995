<template>
  <transition name="fade">
    <div v-show="visible" :class="{ 'vuetify-loading-layer': true, translucid: translucid }">
      <v-progress-circular v-bind="loadingVOptions">
        <v-img :src="getBrandImage" class="brand" />
      </v-progress-circular>
      <div v-if="text" class="text mt-4 mx-auto px-4">{{ text }}</div>
    </div>
  </transition>
</template>

<script>
// Utils
import { getPathImage } from '@/utils'

export default {
  name: 'VuetifyLoadingLayer',
  props: {
    /**
     * https://vuetifyjs.com/en/components/progress-circular/#progress-circular
     */
    loadingVOptions: {
      default() {
        return {
          size: '100',
          width: '4',
          indeterminate: true,
          color: 'white'
        }
      },
      type: Object
    },
    text: {
      type: String,
      default: null
    },
    translucid: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Return brand image
     *
     * @return {string} - brand image
     */
    getBrandImage() {
      return getPathImage('brand-loading.png', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.vuetify-loading-layer {
  position: fixed;
  z-index: 300; // por encima de appDialog y appAlert
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $theme_secondary;
  .brand {
    width: 40px;
  }
  &.translucid {
    opacity: 0.35;
  }
  .text {
    color: #fff;
    text-align: center;
  }
}
@media (max-width: 600px) {
  .vuetify-loading-layer {
    .text {
      width: 320px;
    }
  }
}
</style>
