export default {
  // User
  setUser: (state, value) => (state.user = { ...state.user, ...value }),
  resetUser: (state) => (state.user = null),
  // Set onboarding (section)
  setOnboadingSection: (state, { section, params }) => {
    const onboardingSectionIndex = state.onboarding.findIndex(
      (onboardingSection) => onboardingSection.id === section
    )
    if (onboardingSectionIndex > -1) {
      state.onboarding[onboardingSectionIndex].params = params
    }
  }
}
