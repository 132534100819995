// Constants
import { ADDONS } from '@/constants'
// utils
import { get, isNil } from 'lodash'
// filters
import { formatDate } from '@/filters'

const moment = require('moment')

export default {
  companyData: (state) => {
    return { ...state.data }
  },
  // Configuración del addOn "company"
  companyDataConfig: (state, getters) => {
    const companyData = getters.companyData
    const addOnConfigs = get(companyData, 'addOnConfigs', [])
    const companyConfig = addOnConfigs.find((addon) => {
      return addon.id === ADDONS.company
    })

    return companyConfig && companyConfig.configFields ? companyConfig.configFields : {}
  },
  // Configuración del addOn "company"
  trialPlan: (state, getters) => {
    const companyDataConfig = getters.companyDataConfig
    const trial = get(companyDataConfig, 'trial', null)

    if (isNil(trial)) {
      return null
    }

    const now = new Date()
    const expireDate = moment(formatDate(trial.dateFrom, 'YYYY-MM-DD')).add(trial.days, 'days')

    return {
      ...trial,
      daysLeft: expireDate.diff(now, 'days')
    }
  }
}
