// Constants
import { ADDONS, ROLES } from '@/constants'
// Utils
import { getEnvironmentVariable } from '@/utils'

const TakeAwayOrders = () => import('../views/TakeAwayOrders')
const TakeAwayOrdersHistory = () => import('../views/TakeAwayOrdersHistory')
const TakeAwayCategoriesList = () => import('../views/TakeAwayCategoriesList')
const TakeAwayOrderDetail = () => import('../views/TakeAwayOrderDetail')
const TakeAwayConfigBase = () => import('../views/TakeAwayConfigBase')
const TakeAwayConfigSchedule = () => import('../views/TakeAwayConfigSchedule')
const TakeAwayConfigAreas = () => import('../views/TakeAwayConfigAreas')

const routes = [
  {
    path: '/take-away-category-list',
    name: 'takeAwayCategoryList',
    component: TakeAwayCategoriesList,
    meta: {
      roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value],
      addons: [ADDONS.takeAway]
    }
  },
  {
    path: '/take-away-orders/:preorder?',
    name: 'takeAwayOrders',
    component: TakeAwayOrders,
    meta: {
      roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value],
      addons: [ADDONS.takeAway]
    }
  },
  {
    path: '/take-away-orders-history',
    name: 'takeAwayOrdersHistory',
    component: TakeAwayOrdersHistory,
    meta: {
      roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value],
      addons: [ADDONS.takeAway]
    }
  },
  {
    path: '/take-away-order-detail/:id',
    name: 'takeAwayOrderDetail',
    component: TakeAwayOrderDetail,
    meta: {
      roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value],
      addons: [ADDONS.takeAway]
    }
  },
  // Config
  {
    path: '/take-away-config-base',
    name: 'takeAwayConfigBase',
    component: TakeAwayConfigBase,
    meta: {
      roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value],
      addons: [ADDONS.takeAway]
    }
  },
  {
    path: '/take-away-config-schedule',
    name: 'takeAwayConfigSchedule',
    component: TakeAwayConfigSchedule,
    meta: {
      roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value],
      addons: [ADDONS.takeAway]
    }
  }
]

if (getEnvironmentVariable('VUE_APP_BRAND') !== 'schweppes') {
  routes.push({
    path: '/take-away-config-areas',
    name: 'takeAwayConfigAreas',
    component: TakeAwayConfigAreas,
    meta: {
      roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value],
      addons: [ADDONS.takeAway]
    }
  })
}

export default routes
