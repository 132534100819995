import state from './brand.state'
import mutations from './brand.mutations'
import actions from './brand.actions'
import getters from './brand.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
