// Constans
import { ROLES } from '@/constants'
// Mixin
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapState, mapMutations, mapGetters } from 'vuex'
// Utils
import { isNil, get } from 'lodash'

// Ruta de "login"
const loginPath = 'login'
// Ruta de "login" de partner
const loginPartnerPath = 'loginPartner'
// Ruta de "login" de brand
const loginBrandPath = 'loginBrand'
// Rutas desde donde se puede acceder
const allowedLoginPaths = [loginPath, loginPartnerPath, loginBrandPath, 'register']

export default {
  mixins: [uiMixin],
  computed: {
    ...mapGetters('authentication', ['onboardingCompleted', 'userData']),
    ...mapState('app', ['urlRedirect'])
  },
  watch: {
    userData: {
      handler(newValue, oldValue) {
        // Ruta donde nos encontramos
        const routeName = this.$route.name

        if (newValue === undefined) return

        // Si la ruta en la que nos encontramos es
        // "loginPartner" y el usuario es borrado
        // (vaciado de store) no debemos de hacer nada
        if (
          (routeName === loginPartnerPath || routeName === loginBrandPath) &&
          isNil(newValue.id) &&
          !isNil(oldValue) &&
          !isNil(oldValue.id)
        ) {
          return
        }

        // El usuario ha sido borrado (vaciado store) y NO nos encontramos
        // en la ruta de acceso de los partner (loginPartner), por lo tanto
        // enviamos al usuario al "login"
        if (isNil(newValue.id) && !isNil(oldValue) && !isNil(oldValue.id)) {
          // Devolvemos al usuario al login
          this.routerReplaceTo({
            name: loginPath
          })
        }

        // El usuario ha accedido por alguna de las rutas
        // disponibles para tal fin
        if (
          !isNil(newValue.id) &&
          (isNil(oldValue) || isNil(oldValue.id)) &&
          allowedLoginPaths.indexOf(routeName) > -1
        ) {
          // Url del usuario (rol)
          let urlHome = get(ROLES, `${newValue.role}.urlHome`, '/')

          // First-access (tras registro)
          if (get(this.userData, 'firstAccess', false)) {
            urlHome = '/first-access'
          } // Onboarding
          else if (!this.onboardingCompleted) {
            urlHome = '/onboarding/menu'
          } // Url almacenada
          else if (!isNil(this.urlRedirect)) {
            urlHome = this.urlRedirect
            // reseteamos redirección
            this.setUrlRedirect(null)
          }

          // Redirigimos tras hacer login
          this.routerReplaceTo(urlHome)
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('app', ['setUrlRedirect'])
  }
}
