// Constants
import { ADDONS, ROLES } from '@/constants'

const ImageGalleryList = () => import('../views/ImageGalleryList')

export default [
  {
    path: '/image-gallery',
    name: 'imageGallery',
    component: ImageGalleryList,
    meta: {
      roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value],
      addons: [ADDONS.imageGallery]
    }
  }
]
