// Services
import { getBrandCompanyByBrandId } from '@/services/company'

export default {
  /**
   * Obtenemos datos de la compañía "madre" (brand) del servidor
   * y almacenamos en local
   */
  getBrand: async (
    { commit },
    { brand = null, includeAddOnsConfigs = true, includeSubscriptions = false }
  ) => {
    // Update place in Store
    const data = await getBrandCompanyByBrandId(brand, {
      includeAddOnsConfigs,
      includeSubscriptions
    })
    // Eliminamos los datos
    commit('resetBrand')
    // Establecemos los datos
    commit('setBrand', data)
  }
}
