// Request
import { doRequest } from '@/utils/requestApi'
// Utils
import { isNil } from 'lodash'

/**
 * Crea una nueva ración en DB asociada a un establecimiento
 *
 * @param {Object} data - datos a salvar
 * @param {string} placeId - UID establecimiento
 * @param {Object} options - opciones de guardado (traducciones...)
 * @return {Object} data - datos salvados
 */
export const createRationInPlace = async (data, placeId, options = {}) => {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'post',
    url: !isNil(id) ? `v1/ration/place/${id}` : 'v1/ration/place/',
    data: {
      data: params,
      placeId,
      options
    }
  })

  return result.data
}

/**
 * Obtiene las raciones por defecto
 *
 * @return {Object} - objeto con raciones por defecto
 */
export function getDefaultRations() {
  return {
    0: {
      name: 'Precio único',
      price: null
    }
  }
}

/**
 * Obtiene el "meta" registro de "rations"
 *
 * @param {string} placeId - UID place en la base de datos
 */
export async function getMetaRationByPlaceId(placeId) {
  const result = await doRequest({
    method: 'get',
    url: `v1/ration/meta/place/${placeId}`
  })

  return result.data
}

/**
 * Obtiene todas las raciones de un establecimiento,
 * y si se indica el "brand" también obtenemos las
 * raciones de este (compañía)
 *
 * @param {string} placeId - UID place en la base de datos
 * @param {string} brand - UID del brand asociado al establecimiento
 */
export async function getEveryRationsByPlaceId(placeId, brand = null) {
  const result = await doRequest({
    method: 'get',
    url: isNil(brand) ? `v1/ration/place/${placeId}` : `v1/ration/place/${placeId}/${brand}`
  })

  return result.data
}

/**
 * Obtiene la ración de la BD, usando para ello un UID
 *
 * @param {string} id - UID ration in database
 */
export async function getRationById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/ration/${id}`
  })

  return result.data
}

/**
 * Actualiza una ración en BD
 *
 * @param {Object} data - datos a salvar
 * @param {Object} options - opciones de guardado (traducciones...)
 * @return {Object} data - datos salvados
 */
export const updateRationById = async (data, options = {}) => {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/ration/${id}`,
    data: {
      data: params,
      options
    }
  })

  return result.data
}

/**
 * Actualiza la meta-ración (añadimos hijos, raciones)
 *
 * @param {Object} data - datos a salvar
 */
export const updateMetaRationById = async (data) => {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/ration/meta/${id}`,
    data: {
      data: params
    }
  })

  return result.data
}

/**
 * Elimina una ración (metaFields) de la BD
 *
 * @param {string} id - UID ración (metaFields) en BD
 * @param {string} model - Modelo asociado ('place' or 'company')
 * @param {string} modelId - UID del modelo asociado
 */
export async function deleteRationById(id, model = 'place', modelId) {
  const result = await doRequest({
    method: 'delete',
    url: `v1/ration/${id}`,
    data: {
      model,
      modelId
    }
  })

  return result.data
}
