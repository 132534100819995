import { getEveryRationsByPlaceId } from '@/services/ration'

export default {
  /**
   * Obtiene todas las raciones relacionados al
   * establecimiento
   *
   * @param {object} data - Datos para la petición
   */
  getEveryRations: async ({ commit }, { placeId = null, brand = null }) => {
    try {
      const rations = await getEveryRationsByPlaceId(placeId, brand)
      commit('setRations', rations)
    } catch (error) {
      commit(
        'app/setAppAlert',
        { type: 'error', text: error.message, visible: true },
        { root: true }
      )
    }
  }
}
