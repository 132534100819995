// Contants
import { COMPANY_RELATIONSHIP_TYPES, COMPANY_RELATIONSHIP_STATUS } from '@/constants'
// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Crear nueva relación de compañía
 *
 * @param {string} sourceCompanyId - Id de compañía (propia)
 * @param {string} targetCompanyId - Ide de compañía objetivo (con la que deseamos relacionarnos)
 * @param {object} options - opciones varias (sendEmail, type)
 */
export async function createRelationship(sourceCompanyId, targetCompanyId, options) {
  const result = await doRequest({
    method: 'post',
    url: 'v1/relationship',
    data: {
      sourceCompanyId,
      targetCompanyId,
      options: {
        sendEmail: true,
        type: COMPANY_RELATIONSHIP_TYPES.partner,
        ...options
      }
    }
  })

  return result.data
}

/**
 * Aceptamos el "intento" de relación de la compañía
 *
 * @param {string} relationshipId - Id de la relación
 */
export async function acceptRelationship(relationshipId) {
  const result = await doRequest({
    method: 'patch',
    url: `v1/relationship/${relationshipId}`,
    data: {
      type: COMPANY_RELATIONSHIP_STATUS.accepted
    }
  })

  return result.data
}

/**
 * Cancelamos el "intento" de relación de la compañía
 *
 * @param {string} companyId - Id de la compañía
 * @param {string} type - Tipo de relación
 */
export async function cancelRelationship(companyId, type = COMPANY_RELATIONSHIP_TYPES.partner) {
  const result = await doRequest({
    method: 'patch',
    url: `v1/relationship/cancel`,
    data: {
      companyId,
      type
    }
  })

  return result.data
}
