// Constants
import { ADDONS, ROLES } from '@/constants'

const CrmList = () => import('../views/CrmList')
const CrmDetail = () => import('../views/CrmDetail')

export default [
  {
    path: '/crm',
    name: 'crmList',
    component: CrmList,
    meta: {
      roles: [ROLES.horeca_admin.value],
      addons: [ADDONS.crm]
    }
  },
  {
    path: '/crm/:id',
    name: 'crm-detail',
    component: CrmDetail,
    meta: {
      roles: [ROLES.horeca_admin.value],
      addons: [ADDONS.crm]
    }
  }
]
