export default {
  setHasTakeAwayMenu: (state, value) => {
    state.hasTakeAwayMenu = value
  },
  // orders
  setTakeAwayOrders: (state, value) => {
    state.orders = value
  },
  resetTakeAwayOrders: (state) => (state.orders = []),
  // preOrders
  setTakeAwayPreOrders: (state, value) => {
    state.preOrders = value
  },
  resetTakeAwayPreOrders: (state) => (state.preOrders = [])
}
