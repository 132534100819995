import state from './place.state'
import mutations from './place.mutations'
import actions from './place.actions'
import getters from './place.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
